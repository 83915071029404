import { useEffect, useState } from "react";

import type { Table } from "dexie";

import { useIndexedDB } from "@common/contexts/web-storage/indexed-db.context";

const useDexieDBCleaner = () => {
  const [isCleaned, setIsCleaned] = useState(true);
  const indexDB = useIndexedDB();

  useEffect(() => {
    setIsCleaned(false);

    const tableCleanState = Object.values(indexDB).map((table: Table) =>
      table.clear()
    );

    Promise.all(tableCleanState)
      .then(() => setIsCleaned(true))
      .catch(() => setIsCleaned(false));
  }, [indexDB]);

  return isCleaned;
};

export default useDexieDBCleaner;
