import type { IScaleOption } from "@common/components/scale-factory/scale-factory.component";

import type { TQuestionScaleValues } from "../components/questions-scale/question-scale.component";

const convertValuesToOptions = (values: TQuestionScaleValues) => {
  return [...values.entries()].map<IScaleOption>(([value, label]) => ({
    label,
    value: value.toString(10),
  }));
};

export default convertValuesToOptions;
