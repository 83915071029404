import type {
  AppState,
  AuthorizationParams,
  CacheLocation,
} from "@auth0/auth0-react";
import { Auth0Provider as Auth0ContextProvider } from "@auth0/auth0-react";
import type { FunctionComponent, ReactNode } from "react";

interface IAuth0ContextProps {
  children?: ReactNode;
}

const USE_REFRESH_TOKENS = true;
const CACHE_LOCATION: CacheLocation = "localstorage";
const DOMAIN: string = process.env.REACT_APP_AUTH0_DOMAIN ?? "";
const CLIENT_ID: string = process.env.REACT_APP_AUTH0_CLIENT_ID ?? "";

const AUTHORIZATION_PARAMS: AuthorizationParams = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  redirect_uri: window.location.origin + "/callback",
};

const handleRedirectCallback = (appState: AppState | undefined) => {
  const routeToNavigate = appState?.returnTo ?? window.location.pathname;

  window.location.replace(routeToNavigate);
};

export const Auth0Provider: FunctionComponent<IAuth0ContextProps> = ({
  children,
}) => {
  return (
    <Auth0ContextProvider
      domain={DOMAIN}
      clientId={CLIENT_ID}
      authorizationParams={AUTHORIZATION_PARAMS}
      useRefreshTokens={USE_REFRESH_TOKENS}
      cacheLocation={CACHE_LOCATION}
      onRedirectCallback={handleRedirectCallback}
    >
      {children}
    </Auth0ContextProvider>
  );
};
