/* eslint-disable @typescript-eslint/no-explicit-any */
export type TTokenGetter = (...args: any[]) => Promise<string>;

const DEFAULT_HEADERS: Record<string, string> = {
  "Content-Type": "application/json",
};

interface IFetchErrorConstructor {
  message: string;
  statusCode: number;
  data?: unknown;
}
export class HttpRequestException extends Error {
  public statusCode: number;
  public data?: unknown;

  constructor({ message, statusCode, data }: IFetchErrorConstructor) {
    super(message);
    this.name = "IFetchError";
    this.statusCode = statusCode;
    this.data = data;
  }
}

export const isHttpRequestException = (
  error: unknown
): error is HttpRequestException => {
  return error instanceof HttpRequestException;
};

interface IFetchWithAuthProps {
  url: string;
  requestOptions?: Omit<RequestInit, "headers">;
  headers?: Record<Exclude<string, "authorization">, string>;
  validateStatusCode?: (status: number) => boolean;
}

/**
 * High Order Function
 *
 * @description Performs an authenticated fetch request using the provided tokenGetter function.
 */
export const fetchWithAuth =
  (tokenGetter: TTokenGetter) =>
  async <T = unknown>({
    url,
    requestOptions = {},
    headers = {},
    validateStatusCode,
  }: IFetchWithAuthProps): Promise<T> => {
    const token = await tokenGetter();

    const headersToAdd: HeadersInit = new Headers({
      ...DEFAULT_HEADERS,
      ...headers,
      authorization: `bearer ${token}`, //Static for this function.
    });

    const response = await fetch(url, {
      headers: headersToAdd,
      ...requestOptions,
    });

    // If theres a validateStatusCode function, it will be used to validate the response status.
    if (validateStatusCode && !validateStatusCode(response.status)) {
      const httpRequestException = new HttpRequestException({
        statusCode: response.status,
        message: response.statusText,
        data: await response.json().catch((error) => error),
      });

      throw httpRequestException;
    }

    // If not it will fallback to the default status code validation. 2XX is considered a success.
    if (!response.ok) {
      throw new HttpRequestException({
        statusCode: response.status,
        message: response.statusText,
        data: await response.json().catch((error) => error),
      });
    }

    return await response.json().catch((error) => {
      const httpRequestException = new HttpRequestException({
        message: "Couldn't parse the response body.",
        statusCode: 500,
        data: error,
      });

      throw httpRequestException;
    });
  };
