import { useCallback, useState } from "react";

import { Alert, Grid, Paper } from "@mui/material";
import type { FunctionComponent } from "react";

import { useSocket } from "@common/contexts/socket-io/socket-io.component";
import { useIndexedDB } from "@common/contexts/web-storage/indexed-db.context";
import type { IScaleQuestionAnswer } from "@common/models/answer/answer.model";
import type { IScaleQuestion } from "@common/models/question/question.model";

import type { TQuestionState } from "../../question-status/question-status.component";
import { QuestionStatus } from "../../question-status/question-status.component";
import {
  LIKERT_SCALE_OPTIONS,
  QuestionLikertScale,
} from "../../scales/likert-scale/likert-scale.component";
import type {
  TSocketFailureHandler,
  TSocketSuccessHandler,
} from "../hooks/use-socket-answer-events/use-socket-answer-events.hook";
import { useSocketAnswerEvents } from "../hooks/use-socket-answer-events/use-socket-answer-events.hook";
import type { IQuestionTypeConfig } from "../models/question-type-config.model";

interface IScaleProps {
  question: IScaleQuestion;
}
type TScaleQuestionAnswerRequest = Omit<IScaleQuestionAnswer, "isAnswered">;

const Scale: FunctionComponent<IScaleProps> = ({ question }) => {
  const { QuestionsTable } = useIndexedDB();
  const socket = useSocket();

  const [questionStatus, setQuestionStatus] = useState<TQuestionState>("SAVED");
  const [questionAnswer, setQuestionAnswer] = useState<number>(question.value);

  const { id: questionId, name: questionName } = question;

  /**
   * USED FOR HANDLING SOCKET EVENTS
   */
  const handleAnswerUpdateSuccess = useCallback<
    TSocketSuccessHandler<IScaleQuestionAnswer>
  >(
    async ({ data }) => {
      await QuestionsTable.update(data.id, data);

      setTimeout(() => {
        setQuestionStatus("SAVED");
      }, 650);
    },
    [QuestionsTable]
  );

  const handleUpdateError = useCallback<
    TSocketFailureHandler<IScaleQuestionAnswer>
  >(
    async (error) => {
      const { data: answer } = error;
      setQuestionStatus("ERROR_SAVING");

      if (answer) {
        setQuestionAnswer(answer.value);
        await QuestionsTable.update(questionId, answer);
      } else {
        setQuestionAnswer(question.pastValue);
        await QuestionsTable.update(questionId, {
          value: question.pastValue,
        });
      }

      // Socket was forced to reverse the changes that tried to merge with the server.

      setTimeout(() => {
        setQuestionStatus("SAVED");
      }, 3000);
    },
    [QuestionsTable, question.pastValue, questionId]
  );

  const handleExternalAnswerUpdate = useCallback<
    TSocketSuccessHandler<IScaleQuestionAnswer>
  >(
    async ({ data: answer }) => {
      setQuestionStatus("EXTERNAL_UPDATE");
      setQuestionAnswer(answer.value);

      await QuestionsTable.update(questionId, answer);

      setTimeout(() => {
        setQuestionStatus("SAVED");
      }, 500);
    },
    [QuestionsTable, questionId]
  );

  useSocketAnswerEvents(questionId, {
    onAnswerUpdateSuccess: handleAnswerUpdateSuccess,
    onUpdateError: handleUpdateError,
    onExternalAnswerUpdate: handleExternalAnswerUpdate,
  });

  const handleSubmit = (newValue: number) => {
    setQuestionStatus("SAVING");

    const answer: TScaleQuestionAnswerRequest = {
      id: question.id,
      skillId: question.skillId,
      category: question.category,
      question: questionName,
      type: question.type,
      pastValue: question.value,
      value: newValue,
    };

    socket.emit("ANSWER_UPDATE", answer);
  };

  const handleLikerScaleChange = (value: string) => {
    const questionAnswer = parseInt(value, 10);

    handleSubmit(questionAnswer);
    setQuestionAnswer(questionAnswer);
  };

  if (!question)
    return (
      <Grid container xs={12} md={8} xl={7} sx={{ margin: "0 auto" }}>
        <Alert color="info">
          <p>
            <strong>
              <i className="fas fa-spinner fa-spin"></i>
            </strong>
            <br />
            This question has not been loaded yet.
          </p>
        </Alert>
      </Grid>
    );

  /**
   * ? Create a Likert scale with the question.
   * Save Question ID and Skill ID to set the values in the answers.
   */
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={8} xl={7} marginBottom={2.5}>
        <QuestionStatus questionStatus={questionStatus} />
        <Paper elevation={5}>
          <div className="is-fullwidth p-4">{questionName}</div>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4} xl={5}>
        <QuestionLikertScale
          value={questionAnswer}
          onChange={handleLikerScaleChange}
        />
      </Grid>
    </Grid>
  );
};

// Useful configuration for mapping data related to this question type
export const SCALE_QUESTION_CONFIG: IQuestionTypeConfig = {
  label: "Scale",
  options: LIKERT_SCALE_OPTIONS,
};

export default Scale;
