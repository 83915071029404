import type { FunctionComponent } from "react";

import type { IScaleOption } from "@common/components/scale-factory/scale-factory.component";

import type { TQuestionScaleValues } from "../components/questions-scale/question-scale.component";
import { QuestionScale } from "../components/questions-scale/question-scale.component";

interface IQuestionSatisfactionProps {
  value: unknown;
  onChange: (value: string) => void;
}

export const LIKERT_SCALE_OPTIONS: TQuestionScaleValues = new Map([
  [-1, "Doesn't apply"],
  [1, "None"],
  [2, "Poor"],
  [3, "Good"],
  [4, "Excellent"],
]);

const options = [...LIKERT_SCALE_OPTIONS.entries()].map<IScaleOption>(
  ([value, label]) => ({
    label,
    value: value.toString(10),
  })
);

export const QuestionLikertScale: FunctionComponent<
  IQuestionSatisfactionProps
> = ({ value, onChange }) => {
  return (
    <QuestionScale
      title="Likert Scale"
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
