export const debounce = (
  callback: (...args: unknown[]) => unknown,
  delay = 1000
) => {
  let timeout: NodeJS.Timeout;

  return (...args: unknown[]) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};
