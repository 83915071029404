import { type Reducer, useReducer } from "react";

import type { TToastMessageQueue } from "@common/components/toast-stack/toast-stack.definitions";
import Queue from "@common/data/queue";
import { generateStateUpdater } from "@common/utils/reducers/reducers.util";

import type { TToastStackAction } from "./toast-stack-actions.types";
import { EToastStackAction } from "./toast-stack-actions.types";

export type TToastStackReducerState = {
  messages: TToastMessageQueue[];
  limit: number;

  _messagesQueue: Queue<TToastMessageQueue>;
};

export const INITIAL_STATE: TToastStackReducerState = {
  messages: [],
  limit: 3,

  _messagesQueue: new Queue<TToastMessageQueue>(),
};

const computeMessagesToDisplay = (
  messagesToDisplay: TToastMessageQueue[],
  messagesQueue: Queue<TToastMessageQueue>,
  limit: number
) => {
  if (messagesToDisplay.length < limit && messagesQueue.size > 0) {
    const messagesToPush = limit - messagesToDisplay.length;

    const messagesToAdd: TToastMessageQueue[] = [];

    for (let i = 0; i < messagesToPush; i++) {
      const messageToDisplay = messagesQueue.dequeue();

      if (messageToDisplay === null) {
        continue;
      }

      messagesToAdd.push(messageToDisplay);
    }

    return [...messagesToDisplay, ...messagesToAdd];
  }

  return [...messagesToDisplay];
};

export const toastStackReducer: Reducer<
  TToastStackReducerState,
  TToastStackAction
> = (state, action) => {
  const updateState = generateStateUpdater(state);

  switch (action.type) {
    case EToastStackAction.ADD_MESSAGE: {
      if (state.messages.length >= state.limit) {
        state._messagesQueue.enqueue(action.payload);

        return state;
      }

      const messagesUpdate = [...state.messages, action.payload];

      return updateState({ messages: messagesUpdate });
    }

    case EToastStackAction.REMOVE_MESSAGE_BY_ID: {
      const filteredMessages = state.messages.filter(
        (message) => message.id !== action.payload
      );

      return updateState({
        messages: computeMessagesToDisplay(
          filteredMessages,
          state._messagesQueue,
          state.limit
        ),
      });
    }

    case EToastStackAction.REMOVE_MESSAGE_BY_TYPE: {
      const filteredMessages = state.messages.filter(
        (message) => message.type !== action.payload
      );

      return updateState({
        messages: computeMessagesToDisplay(
          filteredMessages,
          state._messagesQueue,
          state.limit
        ),
      });
    }

    case EToastStackAction.REMOVE_ALL_MESSAGES: {
      // Clear messages queue
      state._messagesQueue.clear();

      return updateState({ messages: [] });
    }

    default:
      return state;
  }
};

export const useToastStackReducer = () => {
  return useReducer(toastStackReducer, INITIAL_STATE);
};
