import type { FunctionComponent, ReactNode } from "react";

import ToastProvider from "@common/components/toast-stack/components/toast-provider/toast-provider.component";
import { ToastStack } from "@common/components/toast-stack/toast-stack.component";

type TMainLayoutProps = {
  children: ReactNode;
};

const MainLayout: FunctionComponent<TMainLayoutProps> = ({ children }) => {
  return (
    <main>
      <ToastProvider>
        <ToastStack />

        {children}
      </ToastProvider>
    </main>
  );
};

export default MainLayout;
