import type { TQuestionState } from "../../../question-status/question-status.component";

import { ETechnicalActionTypes } from "./technical-actions.types";
//************************************************************************ */
//Action Types

export type IToggleEditorAction = {
  type: ETechnicalActionTypes.TOGGLE_EDITOR;
};

export interface IToggleRestoreDefaultQuestionModal {
  type: ETechnicalActionTypes.TOGGLE_RESTORE_DEFAULT_QUESTION_MODAL;
}

export interface ISetQuestionStatusAction {
  type: ETechnicalActionTypes.SET_QUESTION_STATUS;
  payload: TQuestionState;
}

export interface ISetEditorCodeAction {
  type: ETechnicalActionTypes.SET_EDITOR_CODE;
  payload: string;
}

export interface ISetQuestionAnswerAction {
  type: ETechnicalActionTypes.SET_QUESTION_ANSWER;
  payload: number;
}

export type TTechnicalAction =
  | IToggleEditorAction
  | IToggleRestoreDefaultQuestionModal
  | ISetQuestionStatusAction
  | ISetEditorCodeAction
  | ISetQuestionAnswerAction;
//************************************************************************ */
//Actions

export const toggleEditorVisibility = (): IToggleEditorAction => ({
  type: ETechnicalActionTypes.TOGGLE_EDITOR,
});

export const toggleRestoreDefaultQuestionModal =
  (): IToggleRestoreDefaultQuestionModal => ({
    type: ETechnicalActionTypes.TOGGLE_RESTORE_DEFAULT_QUESTION_MODAL,
  });

export const setQuestionStatus = (
  status: TQuestionState
): ISetQuestionStatusAction => ({
  type: ETechnicalActionTypes.SET_QUESTION_STATUS,
  payload: status,
});

export const setEditorCode = (code: string): ISetEditorCodeAction => ({
  type: ETechnicalActionTypes.SET_EDITOR_CODE,
  payload: code,
});

export const setQuestionAnswer = (value: number): ISetQuestionAnswerAction => ({
  type: ETechnicalActionTypes.SET_QUESTION_ANSWER,
  payload: value,
});
