import { useLocation, useNavigate } from "react-router-dom";

import DnsTwoToneIcon from "@mui/icons-material/DnsTwoTone";
import { Container, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import type { FunctionComponent } from "react";

import "./no-interaction.styles.css";

interface IInternalServerErrorProps {
  clearErrorFromErrorBoundary?: () => void;
}

export const InternalServerError500: FunctionComponent<
  IInternalServerErrorProps
> = ({ clearErrorFromErrorBoundary }: IInternalServerErrorProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const errorMessage: string =
    (location.state as Record<string, string>)?.message ??
    "Something went wrong. Please try again later.";

  const handleReturnToDashboardButton = () => {
    clearErrorFromErrorBoundary?.();
    navigate("/dashboard", { replace: true });
  };

  return (
    <Container className="xci-nointeraction-centered">
      <DnsTwoToneIcon className="xci-nointeraction-icon" />

      <Typography
        variant="h4"
        marginBottom={3}
        marginLeft={40}
        marginRight={40}
      >
        {errorMessage}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={handleReturnToDashboardButton}
      >
        Return to dashboard
      </Button>
    </Container>
  );
};
