import { createContext, useContext } from "react";

import Dexie from "dexie";
import type { FunctionComponent, ReactNode } from "react";

import type {
  IQualification,
  ISkill,
} from "@common/models/profile/profile.model";
import type { TQuestion } from "@common/models/question/question.model";

export interface ISkillDB {
  Id: string;
  Name: string;
  Parent_Id?: string;
  Parent_Name?: string;
  Fixed?: boolean;
  BgColor?: string;
  FgColor?: string;
  Children?: boolean;
  Profile?: boolean;
  Profile_Id?: string;
}

export interface IProfileDB {
  Id: string;
  Name: string;
  Description?: string;
  Published: boolean;
  Skills: ISkill[];
  Qualifications: IQualification[];
}
export interface IIndexDBContext {
  SkillsTable: Dexie.Table<ISkillDB, string>;
  ProfilesTable: Dexie.Table<IProfileDB, string>;
  QuestionsTable: Dexie.Table<TQuestion, string>;
}

export type TTableName = keyof IIndexDBContext;

const IndexedDBContext = createContext<IIndexDBContext | null>(null);

/**
 * Please change typescript types to match the database schema!
 * @description IndexedDB database
 */
const db = new Dexie("Interview");

db.version(14).stores({
  Skills: "&Id, Name, Parent_Id, Parent_Name",
  Profiles: "&Id, Name, Description, Published, Skills, Qualifications",
  Questions:
    "&id, name, dateEntered, dateModified, description, category, type, skillId, value, comment, code, codeBase, isAnswered, order, options, multiSelect",
});

const SkillsTable = db.table<ISkillDB, string>("Skills");
const ProfilesTable = db.table<IProfileDB, string>("Profiles");
const QuestionsTable = db.table<TQuestion, string>("Questions");

interface IIndexDBProviderProps {
  children: ReactNode;
}

const CONTEXT_VALUE: IIndexDBContext = {
  SkillsTable,
  ProfilesTable,
  QuestionsTable,
};

export const IndexedDBProvider: FunctionComponent<IIndexDBProviderProps> = ({
  children,
}) => {
  return (
    <IndexedDBContext.Provider value={CONTEXT_VALUE}>
      {children}
    </IndexedDBContext.Provider>
  );
};

export const useIndexedDB = () => {
  const context = useContext(IndexedDBContext);

  if (!context) throw new Error("IndexedDB is not available");

  return context;
};
