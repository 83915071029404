import type { RadioGroupProps } from "@mui/material";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import type { FunctionComponent } from "react";

export interface IScaleOption {
  label: string;
  value: string;
}

interface IScaleFactoryProps extends RadioGroupProps {
  options: IScaleOption[];

  children?: never;
}

export const ScaleFactory: FunctionComponent<IScaleFactoryProps> = ({
  options,
  ...radioGroupProps
}) => (
  <RadioGroup {...radioGroupProps}>
    {options.map(({ label: optionLabel, value: optionValue }) => (
      <FormControlLabel
        key={optionValue}
        value={optionValue}
        control={<Radio />}
        label={optionLabel}
      />
    ))}
  </RadioGroup>
);
