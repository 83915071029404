import { useReducer } from "react";

import type { Reducer } from "react";

import { generateStateUpdater } from "@common/utils/reducers/reducers.util";

import type { TQuestionState } from "../../../question-status/question-status.component";

import type { TTechnicalAction } from "./technical.actions";
import { ETechnicalActionTypes } from "./technical-actions.types";

export interface ITechnicalQuestionReducerState {
  questionStatus: TQuestionState;
  isEditorHidden: boolean;
  showRestoreQuestionModal: boolean;

  editorCode: string;
  questionAnswer: number;
}

export const technicalQuestionReducer: Reducer<
  ITechnicalQuestionReducerState,
  TTechnicalAction
> = (state, action) => {
  const updateState = generateStateUpdater(state);

  switch (action.type) {
    case ETechnicalActionTypes.SET_QUESTION_STATUS: {
      return updateState({
        questionStatus: action.payload,
      });
    }

    case ETechnicalActionTypes.SET_EDITOR_CODE: {
      return updateState({
        editorCode: action.payload,
      });
    }

    case ETechnicalActionTypes.SET_QUESTION_ANSWER: {
      return updateState({
        questionAnswer: action.payload,
      });
    }

    case ETechnicalActionTypes.TOGGLE_EDITOR: {
      return updateState({
        isEditorHidden: !state.isEditorHidden,
      });
    }

    case ETechnicalActionTypes.TOGGLE_RESTORE_DEFAULT_QUESTION_MODAL: {
      return updateState({
        showRestoreQuestionModal: !state.showRestoreQuestionModal,
      });
    }

    default: {
      return state;
    }
  }
};

export const useTechnicalQuestionReducer = (
  initialState: ITechnicalQuestionReducerState
) => {
  return useReducer(technicalQuestionReducer, initialState);
};
