class SimpleNode<T> {
  public value: T;
  public next: SimpleNode<T> | null;

  public constructor(value: T) {
    this.value = value;
    this.next = null;
  }
}

export default SimpleNode;
