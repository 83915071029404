/**
 * @description - Validates if a string is a valid UUID from SuitCRM.
 * @param {string} uuid - The uuid to be validated.
 */
export const isUUID = (uuid?: string): boolean => {
  if (typeof uuid !== "string" || uuid.length < 22) {
    return false;
  }

  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
    uuid
  );
};
