import { useMemo } from "react";

import type { ISocketEvent } from "@common/contexts/socket-io/hooks/use-handle-socket-events/use-handle-socket.events.hook";
import { useHandleSocketEvents } from "@common/contexts/socket-io/hooks/use-handle-socket-events/use-handle-socket.events.hook";
import type {
  ICustomOptionQuestionAnswer,
  IScaleQuestionAnswer,
  ITechnicalQuestionAnswer,
  ITextQuestionAnswer,
  IYesNoQuestionAnswer,
} from "@common/models/answer/answer.model";
import type {
  ISocketError,
  ISocketSuccess,
} from "@common/models/socket/socket.model";

import EAnswerSocketEvents from "./events/answer-events.enum";
import createAnswerEvent from "./utils/create-answer-event";

export type TSocketSuccessHandler<T> = (response: ISocketSuccess<T>) => unknown;
export type TSocketFailureHandler<T> = (
  response: ISocketError<T | undefined>
) => unknown;

type TAnswerUpdateSuccess =
  | TSocketSuccessHandler<ITechnicalQuestionAnswer>
  | TSocketSuccessHandler<IScaleQuestionAnswer>
  | TSocketSuccessHandler<ITextQuestionAnswer>
  | TSocketSuccessHandler<IYesNoQuestionAnswer>
  | TSocketSuccessHandler<ICustomOptionQuestionAnswer>;

type TAnswerUpdateFailure =
  | TSocketFailureHandler<ITechnicalQuestionAnswer>
  | TSocketFailureHandler<IScaleQuestionAnswer>
  | TSocketFailureHandler<ITextQuestionAnswer>
  | TSocketFailureHandler<IYesNoQuestionAnswer>
  | TSocketFailureHandler<ICustomOptionQuestionAnswer>;

interface IUseSocketAnswerEvents {
  onAnswerUpdateSuccess?: TAnswerUpdateSuccess;

  onExternalAnswerUpdate?: TAnswerUpdateSuccess;

  onUpdateError?: TAnswerUpdateFailure;
}
/**
 *  Hook for handling socket events related to interview answers updates.
 */
export const useSocketAnswerEvents = (
  questionId: string,
  {
    onAnswerUpdateSuccess,
    onUpdateError,

    onExternalAnswerUpdate,
  }: IUseSocketAnswerEvents
) => {
  const answerEvents = useMemo<ISocketEvent[]>(() => {
    const generateAnswerEvent = createAnswerEvent(questionId);

    return [
      {
        name: generateAnswerEvent(
          EAnswerSocketEvents.SENDER_ANSWER_UPDATE_SUCCESS_EVENT
        ),
        handler: onAnswerUpdateSuccess,
      },
      {
        name: generateAnswerEvent(
          EAnswerSocketEvents.SENDER_ANSWER_UNEXPECTED_ERROR_EVENT
        ),
        handler: onUpdateError,
      },
      {
        name: generateAnswerEvent(
          EAnswerSocketEvents.CLIENT_ANSWER_UPDATE_EVENT
        ),
        handler: onExternalAnswerUpdate,
      },
    ];
  }, [
    onAnswerUpdateSuccess,
    onExternalAnswerUpdate,
    onUpdateError,
    questionId,
  ]);

  useHandleSocketEvents(answerEvents);
};
