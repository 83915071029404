import type { TQuestionState } from "../../../question-status/question-status.component";

import { ETextActionTypes } from "./text-actions.types";

//************************************************************************ */
//Action Types
export interface ISetQuestionStatusAction {
  type: ETextActionTypes.SET_QUESTION_STATUS;
  payload: TQuestionState;
}

export interface ISetQuestionComment {
  type: ETextActionTypes.SET_QUESTION_COMMENT;
  payload: string;
}

export interface ISetQuestionAnswer {
  type: ETextActionTypes.SET_QUESTION_ANSWER;
  payload: number;
}

export interface ISetActiveTabAction {
  type: ETextActionTypes.SET_ACTIVE_TAB;
  payload: string;
}

export type TTextActions =
  | ISetQuestionStatusAction
  | ISetQuestionComment
  | ISetQuestionAnswer
  | ISetActiveTabAction;

//************************************************************************ */
//Actions

export const setQuestionStatus = (
  questionState: TQuestionState
): ISetQuestionStatusAction => ({
  type: ETextActionTypes.SET_QUESTION_STATUS,
  payload: questionState,
});

export const setQuestionComment = (comment: string): ISetQuestionComment => ({
  type: ETextActionTypes.SET_QUESTION_COMMENT,
  payload: comment,
});

export const setQuestionAnswer = (answer: number): ISetQuestionAnswer => ({
  type: ETextActionTypes.SET_QUESTION_ANSWER,
  payload: answer,
});

export const setActiveTab = (tab: string): ISetActiveTabAction => ({
  type: ETextActionTypes.SET_ACTIVE_TAB,
  payload: tab,
});
