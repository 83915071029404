import type { FunctionComponent } from "react";

import "./loading-logo.styles.css";

export const LoadingLogo: FunctionComponent = () => {
  return (
    <div className="xci-loading">
      <div className="xci-loading-spinner">
        <img
          src="/img/cecropia.svg"
          alt="Cecropia Logo"
          className="xci-loading-logo-svg"
        />
      </div>
    </div>
  );
};
