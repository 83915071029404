import SimpleNode from "./simple-node";

class Queue<T> {
  #head: SimpleNode<T> | null;
  #tail: SimpleNode<T> | null;
  #_size: number;

  public get size() {
    return this.#_size;
  }

  public constructor() {
    const nill = null;

    this.#head = nill;
    this.#tail = nill;
    this.#_size = 0;
  }

  public enqueue(value: T): void {
    const newNode = new SimpleNode(value);

    if (this.#head === null) {
      this.#head = newNode;
      this.#tail = newNode;
      this.#_size++;

      return;
    }

    (this.#tail as NonNullable<SimpleNode<T> | null>).next = newNode;
    this.#tail = newNode;
    this.#_size++;
  }

  public dequeue(): T | null {
    if (this.#head === null) {
      return null;
    }

    const dequeueValue = this.#head.value;

    this.#head = this.#head.next;
    this.#_size--;

    return dequeueValue;
  }

  public peek(): T | null {
    if (this.#head === null) {
      return null;
    }

    return this.#head.value;
  }

  public clear() {
    while (this.isEmpty() === false) {
      this.dequeue();
    }
  }

  public isEmpty(): boolean {
    return this.#head === null;
  }

  public toString(): string {
    let stringRepresentation = `${this.#head?.value ?? ""}`;

    let node = this.#head?.next ?? null;

    while (node !== null) {
      stringRepresentation = `${stringRepresentation} -> ${node.value}`;
      node = node.next;
    }

    return stringRepresentation;
  }
}

export default Queue;
