import type { SxProps, Theme } from "@mui/material";
import { Box, Button, Modal, Typography } from "@mui/material";
import type { FunctionComponent } from "react";

import "./confirmation-model.styles.css";

interface IConfirmationModalProps {
  question: string;
  isOpen: boolean;

  confirmButtonText?: string;
  denyButtonText?: string;

  onDeny: (...args: unknown[]) => unknown;
  onConfirm: (...args: unknown[]) => unknown;
}

const modalStyle: SxProps<Theme> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 5,
};

export const ConfirmationModal: FunctionComponent<IConfirmationModalProps> = ({
  question,
  isOpen,
  confirmButtonText = "Yes",
  denyButtonText = "No",
  onDeny,
  onConfirm,
}) => {
  return (
    <Modal onClose={onDeny} open={isOpen}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={2}
        sx={modalStyle}
      >
        <Box>
          <Typography variant="h5" textAlign="center">
            {question}
          </Typography>
        </Box>

        <Box mt={5} className="confirmation-modal-buttons">
          <Button
            variant="contained"
            color="primary"
            onClick={onDeny}
            size="medium"
          >
            {denyButtonText}
          </Button>

          <Button
            variant="contained"
            color="error"
            onClick={onConfirm}
            size="medium"
          >
            {confirmButtonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
