import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import Editor from "@monaco-editor/react";
import type monaco from "monaco-editor";
import type { ForwardRefRenderFunction, MutableRefObject } from "react";
import { MonacoBinding } from "y-monaco";
import { SocketIOProvider } from "y-socket.io";
import type { YTextEvent } from "yjs";
import { Doc } from "yjs";

import SocketIOConnectionManager from "@common/contexts/socket-io/socket-io.singleton";
import { useCallbackRef } from "@common/hooks/functions/use-callback-ref/use-callback-ref.hook";

type IStandaloneCodeEditor = monaco.editor.IStandaloneCodeEditor;

export interface ICodeEditorRefAPI {
  handleRestoreDefaultQuestion: () => void;
  getCurrentCode: () => string;
}

export type TCodeEditorRef = MutableRefObject<ICodeEditorRefAPI | undefined>;

interface ICodeEditorProps {
  //Data
  readonly socketRoomId: string;
  readonly editorFilePath: string;
  readonly editorDocumentId: string;
  readonly editorDefaultQuestion: string;
  readonly editorHeight: string;

  //Config
  isEditorHidden: boolean;

  //Methods
  handleCodeEditorChange: (event: YTextEvent) => void;
}

const socketConnectionInstance = SocketIOConnectionManager.getInstance();

const CodeEditorComponent: ForwardRefRenderFunction<
  ICodeEditorRefAPI,
  ICodeEditorProps
> = (
  {
    socketRoomId,
    editorFilePath,
    editorDocumentId,
    editorDefaultQuestion,
    editorHeight,

    isEditorHidden,

    handleCodeEditorChange,
  },
  ref
) => {
  const [codeEditorInstance, setCodeEditorInstance] =
    useState<IStandaloneCodeEditor | null>(null);

  const [codeEditorProvider, setCodeEditorProvider] =
    useState<SocketIOProvider | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const [document] = useState(new Doc());

  const codeEditorWrapperProps: { hidden: boolean } = {
    hidden: isEditorHidden,
  };

  //Avoids possible useEffect trigger by this function
  const handleCodeEditorChangeRef = useCallbackRef(handleCodeEditorChange);

  /**
   * EXPOSES INTERNAL API TO FATHER COMPONENTS
   */
  useImperativeHandle(
    ref,
    () => ({
      handleRestoreDefaultQuestion: () => {
        codeEditorInstance?.getModel()?.setValue(editorDefaultQuestion);
      },
      getCurrentCode: () => codeEditorInstance?.getModel()?.getValue() ?? "",
    }),
    [codeEditorInstance, editorDefaultQuestion]
  );

  const handleEditorMount = useCallback(
    (editor: IStandaloneCodeEditor) => {
      if (!editor) {
        return;
      }

      const documentText = document.getText(editorDocumentId);
      const codeEditorRoom = `ce-:${socketRoomId}`;

      const editorProvider = new SocketIOProvider(
        socketConnectionInstance.wsURL,
        codeEditorRoom,
        document,
        {
          autoConnect: true,
        }
      );

      const currentEditorModel = editor.getModel() as monaco.editor.ITextModel;

      currentEditorModel?.setEOL(0);

      new MonacoBinding(
        documentText,
        currentEditorModel,
        new Set([editor]),
        editorProvider.awareness
      );

      setCodeEditorProvider(editorProvider);
      setCodeEditorInstance(editor);
    },
    [document, editorDocumentId, socketRoomId]
  );

  /**
   * HANDLES YJS DOCUMENT LOAD AND EVENTS.
   */
  useEffect(() => {
    //Waits for Code Editor Provider
    if (!codeEditorProvider) {
      return;
    }

    const documentText = document.getText(editorDocumentId);

    const handleDocumentChange = (event: YTextEvent) => {
      if (!event.transaction.local) {
        return;
      }

      //In case it was internal send the update to all clients.
      handleCodeEditorChangeRef.current(event);
    };

    //Logic to init document.
    documentText.observe(handleDocumentChange);

    const handleEditorSync = () => {
      setLoading(false);
    };

    codeEditorProvider.on("sync", handleEditorSync);

    const codeEditorCleanup = () => {
      documentText.unobserve(handleDocumentChange);
      codeEditorProvider.off("sync", handleEditorSync);
      codeEditorProvider.disconnect();
    };

    return codeEditorCleanup;
  }, [
    codeEditorProvider,
    document,
    editorDocumentId,
    handleCodeEditorChangeRef,
  ]);

  return (
    <Editor
      path={editorFilePath}
      height={editorHeight}
      theme="light"
      onMount={handleEditorMount}
      wrapperProps={codeEditorWrapperProps}
      loading={loading}
    />
  );
};

/**
 * A code editor component based on the Monaco editor, with Y.js real-time collaboration support.
 *
 * It exposes ref with handleRestoreDefaultQuestion to restore default question.
 */
export const CodeEditor = React.forwardRef(CodeEditorComponent);
