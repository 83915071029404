import type { FunctionComponent } from "react";

import "./loading-droplets.styles.css";

interface ILoadingDropletsProps {
  children?: never;
  className?: string;
}

export const LoadingDroplets: FunctionComponent<ILoadingDropletsProps> = ({
  className = "loading-background-container",
}) => {
  return (
    <div className={className}>
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
