import { Card, CardContent, Typography } from "@mui/material";
import type { FunctionComponent } from "react";
import { CircularProgressbar } from "react-circular-progressbar";

import type { TQuestion } from "@common/models/question/question.model";

import "react-circular-progressbar/dist/styles.css";

interface IAnswerTrackerProps {
  answers: TQuestion[];
  answerTrackerLabel: string;
}

export const AnswerTracker: FunctionComponent<IAnswerTrackerProps> = ({
  answers,
  answerTrackerLabel,
}) => {
  const answerWithScore = answers.reduce((total, question) => {
    const answerScore = Number(question.value);

    if (answerScore === -1) {
      return total;
    }

    return total + 1;
  }, 0);

  const answerScoreText = `${answerWithScore}/${answers.length}`;
  const answerScoreValue = (answerWithScore / answers.length) * 100;
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="h6" className="mb-3">
          Tracker
        </Typography>
        <CircularProgressbar
          value={answerScoreValue}
          text={answerScoreText}
          className="mb-4"
        />

        <Typography variant="body1" component="div">
          {answerTrackerLabel}
        </Typography>
      </CardContent>
    </Card>
  );
};
