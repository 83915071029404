import { Card, CardContent, Typography } from "@mui/material";
import type { FunctionComponent } from "react";
import { CircularProgressbar } from "react-circular-progressbar";

import type { TQuestion } from "@common/models/question/question.model";

import { getAnswerScorePercentage } from "./score.util";

import "react-circular-progressbar/dist/styles.css";

interface IAnswersScore {
  answers?: TQuestion[];
  scoreByAnswer: number;
  answerTypeLabel: string;
}

/**
 * Component for displaying answer type global score percentage
 */
export const AnswersScore: FunctionComponent<IAnswersScore> = ({
  answers = [],
  scoreByAnswer,
  answerTypeLabel,
}) => {
  const score = Math.round(getAnswerScorePercentage(answers, scoreByAnswer));
  const scoreText = `${score.toFixed(2)}%`;
  return (
    <Card className="xci-review-global-progress">
      <CardContent>
        <Typography variant="h6" component="h6" className="mb-3">
          Score
        </Typography>

        <CircularProgressbar value={score} text={scoreText} className="mb-4" />

        <Typography variant="body1" component="p">
          {answerTypeLabel}
        </Typography>
      </CardContent>
    </Card>
  );
};
