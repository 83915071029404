import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import type { LogoutOptions } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Grid, Typography } from "@mui/material";

const SX_PROPS = {
  boxContainerStyled: {
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    position: "absolute",
    padding: "10%",
  },
};

const LOGOUT_OPTIONS: LogoutOptions = {
  logoutParams: {
    returnTo: window.location.origin + "/logout",
  },
};

export const Logout = () => {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      logout(LOGOUT_OPTIONS);
    } else {
      navigate("/logout");
    }
  }, [isAuthenticated, logout, navigate]);

  useEffect(() => {
    const redirect = setTimeout(() => navigate("/login"), 5000);

    return () => clearTimeout(redirect);
  }, [navigate]);

  const handleSendToLoginButton = () => navigate("/login");

  if (isAuthenticated) {
    return null;
  }

  return (
    <Box sx={SX_PROPS.boxContainerStyled}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={4}>
          <Box display="flex" justifyContent="center" marginBottom="2rem">
            <img
              src="/img/multiplied_logo_vertical.png"
              alt="Cecropia Logo"
              style={{ width: "12rem" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" align="center" gutterBottom>
                  You have already logged out.
                </Typography>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" align="center" gutterBottom>
                  If this page is not closed in 5 seconds, please click the
                  button below.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" marginTop="1rem">
                  <Button variant="contained" onClick={handleSendToLoginButton}>
                    Send me to the login page
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Logout;
