import { useEffect, useRef } from "react";

import { useSocket } from "../../socket-io.component";

export interface ISocketEvent {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler?: (...args: any[]) => any;
  type?: "on" | "once";
}

/**
 * @description Custom hook to simplify handling Socket.IO events in a React component.
 */
export const useHandleSocketEvents = (events: ISocketEvent[]) => {
  const socket = useSocket();

  const previousEventsRef = useRef<Map<string, ISocketEvent["handler"]>>(
    new Map()
  );

  useEffect(() => {
    if (!socket) {
      return;
    }

    const cleanPreviousEvents = () => {
      if (!socket) {
        return;
      }

      previousEventsRef.current.forEach((handler, name) => {
        socket.off(name, handler);
      });

      previousEventsRef.current.clear();
    };

    //Clean events on mount or when events changes
    cleanPreviousEvents();

    //Bind events
    events.forEach(({ name, handler, type = "on" }) => {
      if (!name || !handler) {
        return;
      }

      socket[type](name, handler);
      previousEventsRef.current.set(name, handler);
    });

    return () => {
      cleanPreviousEvents();
    };
  }, [events, socket]);
};
