import { useLayoutEffect, useRef } from "react";

/**
 *
 * @description
 * The `useCallbackRef` hook is used to create a ref that holds a callback function. This hook is useful when you need to maintain a reference to a callback that may change over time. The `callback` parameter is the function to be stored in the ref. The callback function can take any number of arguments and should return a value of type `T`.
 *
 * The hook internally uses the `useRef` hook to create a mutable ref object and initializes it with the provided `callback`.
 *
 * The hook provides a way to access the latest version of the callback stored in the ref across re-renders. However, it doesn't control re-renders triggered by external factors such as changes in state or props.
 *
 * Example usage:
 * ```jsx
 * const MyComponent = () => {
 *   const callbackRef = useCallbackRef((data) => {
 *     console.log(data);
 *   });
 *
 *   // Use the callbackRef as needed...
 *
 *   return <div>...</div>;
 * };
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCallbackRef = <T extends (...args: any[]) => any>(
  callback: T
) => {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return callbackRef;
};
