import { type FunctionComponent } from "react";

import type { SnackbarOrigin } from "@mui/material";
import { Box, Snackbar, Stack } from "@mui/material";

import ToastMessage from "./components/toast-message/toast-message.component";
import { useToastStack } from "./components/toast-provider/toast-provider.component";

export const ToastStack: FunctionComponent = () => {
  const { messages, removeMessageById } = useToastStack();

  const snackBarAnchorOrigin: SnackbarOrigin = {
    vertical: "bottom",
    horizontal: "left",
  };

  return (
    <Snackbar
      open={true}
      anchorOrigin={snackBarAnchorOrigin}
      transitionDuration={0}
      autoHideDuration={null}
    >
      <Box>
        <Stack flexDirection="column" gap={1}>
          {messages.map(
            ({ id, type = "information", title, message, timeout = 3500 }) => {
              const handleToastMessageTimeout = () => {
                removeMessageById(id);
              };

              return (
                <ToastMessage
                  key={id}
                  id={id}
                  type={type}
                  title={title}
                  message={message}
                  timeout={timeout}
                  onTimeout={handleToastMessageTimeout}
                />
              );
            }
          )}
        </Stack>
      </Box>
    </Snackbar>
  );
};
