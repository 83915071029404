import type { ExtractedPropsType } from "@common/utils/typescript/objects/extract-props-types.util";

import type * as ToastStackAction from "./toast-stack.actions";

export enum EToastStackAction {
  "ADD_MESSAGE",
  "REMOVE_MESSAGE_BY_ID",
  "REMOVE_ALL_MESSAGES",
  "REMOVE_MESSAGE_BY_TYPE",
}

export type TToastStackAction = ReturnType<
  ExtractedPropsType<typeof ToastStackAction>
>;
