import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import type { FunctionComponent } from "react";

import { ErrorBoundary } from "@common/components/error-boundary/error-boundary.component";
import { Auth0Provider } from "@common/contexts/auth0/auth0-provider";
import { TriggerCallback } from "@common/contexts/auth0/components/trigger-callback/trigger-callback.component";
import { Unauthorized401 } from "@screens/public/errors/401/401.screen";
import { InternalServerError500 } from "@screens/public/errors/500/500.screen";
import { Login } from "@screens/public/login/login.screen";
import { Logout } from "@screens/public/logout/logout.screen";

import PrivateRouter from "../private/private.router";
import ProtectedRoutes from "../protected/protected.router";

const AuthLayout: FunctionComponent = () => {
  return (
    <Auth0Provider>
      <Outlet />
    </Auth0Provider>
  );
};

export const PublicRouter: FunctionComponent = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="/login" element={<Login />} />

        <Route
          path="/callback"
          element={
            <TriggerCallback
              ErrorComponent={<Unauthorized401 />}
              SuccessComponent={<></>}
            />
          }
        />
        <Route path="/logout" element={<Logout />} />
        <Route path="/401" element={<Unauthorized401 />} />
        <Route path="/500" element={<InternalServerError500 />} />

        <Route
          path="/*"
          element={
            <ErrorBoundary>
              <ProtectedRoutes>
                <PrivateRouter />
              </ProtectedRoutes>
            </ErrorBoundary>
          }
        />
      </Route>
    </Routes>
  );
};
