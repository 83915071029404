import { type FunctionComponent, useEffect, useState } from "react";

import { Close } from "@mui/icons-material";
import type { SxProps } from "@mui/material";
import { Box, Divider, Grow, SnackbarContent, Typography } from "@mui/material";

import type { TToastStackType } from "../../toast-stack.definitions";
import { getTypeIcon, SX_PROPS } from "../../toast-stack.styles";
import { useToastStack } from "../toast-provider/toast-provider.component";

import { getBackgroundColor, getFontColor } from "./toast-message.styles";

type TToastMessageProp = {
  id: string;
  timeout: number | null; // If null it won't set a timeout
  title: string;
  message: string;
  type: TToastStackType;

  /**
   * Function to execute when message timeout finishes
   * @param id - Message ID
   * @returns
   */
  onTimeout: (id: string) => unknown;
};

const ANIMATION_CLOSE_TIMEOUT = 300;

const ToastMessage: FunctionComponent<TToastMessageProp> = ({
  id,
  type,
  message,
  title,
  timeout,

  onTimeout,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const toastStack = useToastStack();

  const sxProps: SxProps = {
    backgroundColor: getBackgroundColor(type),
    color: getFontColor(type),
    borderRadius: "4px",
  };

  // Effect for handling message timeout
  useEffect(() => {
    if (timeout === null) {
      return;
    }

    let internalAnimationTimeout: NodeJS.Timeout;

    const handleMessageTimeout = () => {
      setIsOpen(false);

      const handleInternalAnimationTimeout = () => {
        onTimeout(id);
      };

      internalAnimationTimeout = setTimeout(
        handleInternalAnimationTimeout,
        ANIMATION_CLOSE_TIMEOUT
      );
    };

    const messageTimeoutTimerId = setTimeout(handleMessageTimeout, timeout);

    return () => {
      clearTimeout(messageTimeoutTimerId);
      clearTimeout(internalAnimationTimeout);
    };
  }, [id, onTimeout, timeout]);

  const handleButtonClose = () => {
    setIsOpen(false);

    setTimeout(() => {
      toastStack.removeMessageById(id);
    }, ANIMATION_CLOSE_TIMEOUT);
  };

  const Icon = getTypeIcon(type);

  return (
    <Grow in={isOpen} timeout={ANIMATION_CLOSE_TIMEOUT}>
      <SnackbarContent
        sx={sxProps}
        message={
          <Box>
            <Box sx={SX_PROPS.messageBoxStyle}>
              {Icon}

              <Typography variant="body1">{title}</Typography>
            </Box>

            <Divider sx={{ marginBottom: "4px" }} />

            <Typography variant="body2">{message}</Typography>
          </Box>
        }
        action={
          <Box
            component="span"
            onClick={handleButtonClose}
            sx={SX_PROPS.actionBoxStyle}
          >
            <Close />
          </Box>
        }
      />
    </Grow>
  );
};

export default ToastMessage;
