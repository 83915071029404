import { createContext, useContext, useEffect, useRef, useState } from "react";

import { type IdToken, useAuth0 } from "@auth0/auth0-react";
import type { DefaultEventsMap } from "@socket.io/component-emitter";
import type { FunctionComponent, ReactNode } from "react";
import { useNavigate } from "react-router";
import type { Socket } from "socket.io-client";

import SocketIOConnectionManager from "./socket-io.singleton";

type SocketIOType = Socket<DefaultEventsMap, DefaultEventsMap> | null;

const SocketContext = createContext<SocketIOType>(null);

interface ISocketIOProviderProps {
  children: ReactNode;
}

export const SocketIOProvider: FunctionComponent<ISocketIOProviderProps> = ({
  children,
}) => {
  const [socket, setSocket] = useState<SocketIOType>(null);
  // const pingTimeoutIDRef = useRef<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } =
    useAuth0();

  useEffect(() => {
    const socketInstance = SocketIOConnectionManager.getInstance();

    const handleDisconnection = () => {
      socketInstance.disconnectSocketIO();
    };

    window.addEventListener("beforeunload", handleDisconnection);

    return () => {
      // pingTimeoutIDRef.current && clearTimeout(pingTimeoutIDRef.current);
      window.removeEventListener("beforeunload", handleDisconnection);

      handleDisconnection();
    };
  }, []);

  useEffect(() => {
    const socketInstance = SocketIOConnectionManager.getInstance();

    if (!isAuthenticated) {
      socketInstance.disconnectSocketIO();

      return navigate("/login");
    }

    if (!socketInstance.isSocketIOInitialized) {
      (async () => {
        try {
          const authData = [
            getAccessTokenSilently(),
            getIdTokenClaims(),
          ] as const;

          const [token, claims] = (await Promise.all(authData)) as [
            string,
            IdToken | undefined
          ];

          if (!claims) {
            throw new Error("Failed to get claims");
          }

          const socketIOInstance = socketInstance.initSocketIO({
            token,
            claims,
          });

          const handleSuccess = () => {
            // pingTimeoutIDRef.current = setInterval(() => {
            //   socketIOInstance.isSocketIOConnected &&
            //     socketIOInstance.socketConnection.emit("PING");
            // }, 10000);

            setSocket(socketIOInstance.socketConnection);
          };

          const handleError = (_: unknown) => {
            socketInstance.disconnectSocketIO();
            navigate("/500");
          };

          const socket = socketIOInstance.socketConnection;

          socket.once("connect", handleSuccess);
          socket.once("error", handleError);
        } catch (e) {
          socketInstance.disconnectSocketIO();
          //Temporal fix to /token error 500 scope undefined
          window.localStorage.clear();

          // Redirects to login and force to refresh page.
          navigate("/login");
          navigate(0);
        }
      })();
    }
  }, [getAccessTokenSilently, getIdTokenClaims, isAuthenticated, navigate]);

  if (!socket) {
    return null;
  }

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }

  return context;
};
