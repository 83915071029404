import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { zodResolver } from "@hookform/resolvers/zod";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import type { FunctionComponent } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import z from "zod";

import { isUUID } from "@common/utils/validators/uuid-validator/uuid-validator";
import { useAuthRoute } from "@routers/protected/protected.router";

const SX_PROPS = {
  dashboardContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center", // Center horizontally
    width: "50%",
    margin: "0 auto",
    minHeight: "85vh",
  },
  interpreterIconStyle: {
    fontSize: "35vh",
    color: "#00bcd4",
  },
  fullWidthStyle: {
    width: "100%",
  },
};

const uuidValidator = z
  .string({
    required_error: "Please enter interview code.",
    description: "Interview code validator.",
  })
  .refine(isUUID, "Please enter a valid interview code.");

const DashboardFormSchema = z.object({
  code: uuidValidator,
});

type TDashboardFormSchema = z.infer<typeof DashboardFormSchema>;

export const Dashboard: FunctionComponent = () => {
  const { isInterviewer } = useAuthRoute();
  const navigate = useNavigate();
  const codeRef = useRef<HTMLInputElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<TDashboardFormSchema>({
    resolver: zodResolver(DashboardFormSchema),
  });

  const onSubmit: SubmitHandler<TDashboardFormSchema> = ({ code }) => {
    navigate(`/interview/${code}`);
  };

  const handleCreateNewInterviewClick = () => {
    navigate("/interview/new");
  };

  return (
    <Box
      sx={SX_PROPS.dashboardContainer}
      display="flex"
      justifyContent="center"
    >
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          lg={5}
          display="flex"
          justifyContent="center"
          paddingRight={10}
        >
          <InterpreterModeIcon sx={SX_PROPS.interpreterIconStyle} />
        </Grid>

        <Grid item xs={12} lg={7}>
          <Typography variant="h2" component="h2">
            Welcome to the Interviews Dashboard
          </Typography>

          <Grid item xs={12} marginTop={1}>
            <Typography variant="subtitle1" component="p">
              Please insert the code, we will then redirect you to the interview
            </Typography>
          </Grid>

          {isInterviewer && (
            <Grid item xs={12} marginTop={1}>
              <Typography variant="subtitle1" component="p">
                <strong>The code was sent to your email</strong>
              </Typography>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            marginTop={1}
          >
            <TextField
              variant="outlined"
              inputRef={codeRef}
              label="Interview Code"
              sx={SX_PROPS.fullWidthStyle}
              {...register("code")}
              error={!!formErrors.code}
              helperText={formErrors.code?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Box marginTop={1}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={SX_PROPS.fullWidthStyle}
                onClick={handleSubmit(onSubmit)}
              >
                Join Interview
              </Button>
            </Box>

            <Box marginTop={1}>
              {isInterviewer && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateNewInterviewClick}
                  sx={SX_PROPS.fullWidthStyle}
                >
                  New Interview
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
