import { Card, CardContent, Typography } from "@mui/material";
import type { FunctionComponent } from "react";

import "./answer-detail.styles.css";

export interface IAnswerDetailCardProps {
  skillName: string;
  question: string;
  answer: string;
}

export const AnswerDetailCard: FunctionComponent<IAnswerDetailCardProps> = ({
  skillName,
  question,
  answer,
}) => {
  return (
    <Card className="xci-review-card">
      <CardContent>
        <Typography variant="h5" component="div">
          {answer}
        </Typography>

        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {skillName ?? "Skill not indexed"}
        </Typography>

        <Typography variant="body2">{question}</Typography>
      </CardContent>
    </Card>
  );
};
