import { Navigate } from "react-router-dom";

import type { RedirectLoginOptions } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import type { SxProps } from "@mui/material";
import { Box, Button, Container, Grid, Typography } from "@mui/material";

import "./login.styles.css";

const LOGIN_BUTTON_STYLES: SxProps = {
  ":hover": {
    bgcolor: "#4d5154",
  },
  backgroundColor: "#212529",
};

const FONT_COLOR_STYLE: SxProps = {
  color: "#000000",
};

const REDIRECT_LOGIN_OPTIONS: RedirectLoginOptions = {
  authorizationParams: {
    prompt: "login",
  },
  appState: {
    returnTo: "/dashboard",
  },
  fragment: "!",
};

const AuthLogoElement = (
  <img src="/img/auth0.svg" alt="Auth0 logo" width="16px" />
);

export const Login = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  const handleLoginWithAuth0Button = () => {
    loginWithRedirect(REDIRECT_LOGIN_OPTIONS);
  };

  return (
    <Box className="xci-login-screen">
      <Container className="xci-login-box">
        <Grid
          container
          justifyContent="space-between"
          paddingLeft={15}
          paddingRight={15}
        >
          <Grid
            item
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <img
              src="/img/multiplied_logo_vertical.png"
              alt="Cecropia Logo"
              className="login__logo"
            />
          </Grid>

          <Grid
            item
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={FONT_COLOR_STYLE}
            >
              Careers - Interviews
            </Typography>

            <Typography variant="body1" marginBottom={1} sx={FONT_COLOR_STYLE}>
              Welcome to the Interviews web application.
            </Typography>

            <Typography variant="body1" marginBottom={3} sx={FONT_COLOR_STYLE}>
              Please login to continue.
            </Typography>

            <Button
              variant="contained"
              onClick={handleLoginWithAuth0Button}
              startIcon={AuthLogoElement}
              sx={LOGIN_BUTTON_STYLES}
              size="medium"
            >
              Login with Auth0
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Login;
