import { createContext, lazy, Suspense, useContext, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import type {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
} from "react";

import { LoadingDroplets } from "@common/components/loading-droplets/loading-droplets.component";
import type {
  IProfileDB,
  ISkillDB,
} from "@common/contexts/web-storage/indexed-db.context";
import type {
  IIntervieweeInterview,
  TInterviewInterviewer,
} from "@common/models/interview/interview.model";
import { isUUID } from "@common/utils/validators/uuid-validator/uuid-validator";
import { RestrictTo, useAuthRoute } from "@routers/protected/protected.router";

import useInterviewLoader from "./hooks/use-interview-loader/use-interview-loader";
import ClosedScreen from "./screens/closed/closed.screen";
import LoadingScreen from "./screens/loading/loading.screen";
import NotFound from "./screens/not-found/not-found.screen";

const LazyIntervieweeScreen = lazy(
  () => import("./screens/interviewee/screens/interviewee/interviewee.screen")
);

const LazyEditorScreen = lazy(
  () => import("./screens/interviewer/screens/editor/editor.screen")
);

const LazyCreateInterviewScreen = lazy(
  () =>
    import(
      "./screens/interviewer/screens/create-interview/create-interview.screen"
    )
);

interface IInterviewContext<I> {
  setProfileOptions: Dispatch<SetStateAction<IProfileDB[]>>;
  profileOptions: IProfileDB[];

  setSkillOptions: Dispatch<SetStateAction<ISkillDB[]>>;
  skillOptions: ISkillDB[];

  setInterview: Dispatch<
    SetStateAction<TInterviewInterviewer | IIntervieweeInterview | undefined>
  >;
  interview: I | undefined;
}

const InterviewContext = createContext<
  IInterviewContext<TInterviewInterviewer | IIntervieweeInterview> | undefined
>(undefined);

interface IWithUUIDRouteProps {
  children: ReactNode;
}

/**
 * Checks if route has a valid UUID param in the URL.
 */
const WithUUIDRoute: FunctionComponent<IWithUUIDRouteProps> = ({
  children,
}) => {
  const { uuid: interviewId } = useParams();

  if (!isUUID(interviewId)) {
    const state = {
      reason: {
        message: "It seems that the interview code is invalid...",
        code: "USER_NAVIGATE_WITHOUT_CHART",
      },
    };

    return <Navigate to="/interview/not-found" state={state} />;
  }

  return <>{children}</>;
};

interface IWIthInterviewLoader {
  children: ReactNode;
}
const WithInterviewLoader: FunctionComponent<IWIthInterviewLoader> = ({
  children,
}) => {
  const { uuid: interviewId } = useParams();

  const isLoading = useInterviewLoader(interviewId as string);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};

const RedirectToInterview: FunctionComponent = () => {
  const { isInterviewer } = useAuthRoute();
  const { uuid: interviewId } = useParams();

  const baseRoute = `/interview/${interviewId}`;

  if (isInterviewer) {
    return <Navigate to={`${baseRoute}/editor`} />;
  }

  return <Navigate to={`${baseRoute}/code`} />;
};

export const Interview: FunctionComponent = () => {
  const [profileOptions, setProfileOptions] = useState<IProfileDB[]>([]);
  const [skillOptions, setSkillOptions] = useState<ISkillDB[]>([]);
  const [interview, setInterview] = useState<
    TInterviewInterviewer | IIntervieweeInterview | undefined
  >(undefined);

  const contextValue = {
    setProfileOptions,
    profileOptions,
    setSkillOptions,
    skillOptions,
    setInterview,
    interview,
  };

  return (
    <InterviewContext.Provider value={contextValue}>
      <Routes>
        <Route element={<RestrictTo allowedRoles={["interviewer"]} />}>
          <Route
            path="/:uuid/editor"
            element={
              <WithUUIDRoute>
                <WithInterviewLoader>
                  <Suspense fallback={<LoadingDroplets />}>
                    <LazyEditorScreen />
                  </Suspense>
                </WithInterviewLoader>
              </WithUUIDRoute>
            }
          />
        </Route>

        <Route
          path="/:uuid/code"
          element={
            <WithUUIDRoute>
              <WithInterviewLoader>
                <Suspense fallback={<LoadingDroplets />}>
                  <LazyIntervieweeScreen />
                </Suspense>
              </WithInterviewLoader>
            </WithUUIDRoute>
          }
        />

        <Route
          path="/:uuid"
          element={
            <WithUUIDRoute>
              <RedirectToInterview />
            </WithUUIDRoute>
          }
        />

        <Route
          path="/new"
          element={
            <Suspense fallback={<LoadingDroplets />}>
              <LazyCreateInterviewScreen />
            </Suspense>
          }
        />

        <Route path="/not-found" element={<NotFound />} />
        <Route path="/:uuid/closed" element={<ClosedScreen />} />
        <Route
          path="/*"
          element={
            <Navigate
              replace
              to="/not-found"
              state={{
                reason: {
                  message:
                    "It seems that you are navigating without a chart...",
                  code: "USER_NAVIGATE_WITHOUT_CHART",
                },
              }}
            />
          }
        />
      </Routes>
    </InterviewContext.Provider>
  );
};

/**
 * Generic Hook for getting interview context data.
 */
export const useInterview = <
  T extends
    | TInterviewInterviewer
    | IIntervieweeInterview = IIntervieweeInterview
>() => {
  const context = useContext(InterviewContext) as IInterviewContext<T>;

  if (context === undefined) {
    throw new Error("useInterview must be used inside Interview Context.");
  }
  return context;
};

export default Interview;
