import { useNavigate, useSearchParams } from "react-router-dom";

import { Alert, Button, Container, Grid, Typography } from "@mui/material";
import type { FunctionComponent } from "react";

export const Unauthorized401: FunctionComponent = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});
  const error_description = searchParams.get("error_description");

  return (
    <Container
      style={{
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
        position: "absolute",
        padding: "10%",
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <img
            src="/img/cecropia.svg"
            alt="Cecropia Logo"
            style={{ width: "12rem" }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={14} xs={14} textAlign="center">
          <Typography variant="h4" marginBottom={2}>
            Login process is required
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            We can&apos;t determine your identity. Please try to login again.
          </Typography>
          <Button variant="contained" onClick={() => navigate("/login")}>
            Send me to login page
          </Button>
        </Grid>
      </Grid>

      <hr />

      <Grid item justifyContent="center">
        <Alert variant="standard" color="error">
          <h1>401 - Unauthorized</h1>
          <hr />
          <p>The request requires user authentication.</p>
          <p>{error_description ?? "Error description was not provided."}</p>
          <p>We can do nothing about it.</p>
        </Alert>
      </Grid>
    </Container>
  );
};

export default Unauthorized401;
