import styled from "styled-components";

interface IQuestionStatus {
  $backgroundColor: string;
  $color: string;
}

export const QuestionStatusStyled = styled.div<IQuestionStatus>`
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => props.$color};
  text-align: center;
  border-radius: 12px 12px 0 0;
`;
