/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef } from "react";

import { Slide } from "@mui/material";
import type { TransitionProps } from "@mui/material/transitions";
import type { ReactElement, Ref } from "react";

interface ISlideUpProps extends TransitionProps {
  children: ReactElement<any, any>;
}

export const SlideUp = forwardRef(function Transition(
  props: ISlideUpProps,
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
