import { useCallback, useRef, useState } from "react";

import type { SxProps } from "@mui/material";
import { Alert, AppBar, Grid, Tab, Tabs, Typography } from "@mui/material";
import type { FunctionComponent, Ref } from "react";
import type { YTextEvent } from "yjs";

import { ConfirmationModal } from "@common/components/modals/confirmation-modal/confirmation-modal.component";
import { useSocket } from "@common/contexts/socket-io/socket-io.component";
import { useIndexedDB } from "@common/contexts/web-storage/indexed-db.context";
import { useCallbackRef } from "@common/hooks/functions/use-callback-ref/use-callback-ref.hook";
import { useDebounce } from "@common/hooks/functions/use-debounce/use-debounce.hook";
import type { ITechnicalQuestionAnswer } from "@common/models/answer/answer.model";
import type { ITechnicalQuestion } from "@common/models/question/question.model";
import type { ICodeEditorRefAPI } from "@screens/private/interview/components/code-editor/code-editor.component";
import { CodeEditor } from "@screens/private/interview/components/code-editor/code-editor.component";

import { QuestionStatus } from "../../question-status/question-status.component";
import {
  QuestionSatisfaction,
  SATISFACTION_SCALE_OPTIONS,
} from "../../scales/question-satisfaction-scale/question-satisfaction-scale.component";
import type {
  TSocketFailureHandler,
  TSocketSuccessHandler,
} from "../hooks/use-socket-answer-events/use-socket-answer-events.hook";
import { useSocketAnswerEvents } from "../hooks/use-socket-answer-events/use-socket-answer-events.hook";
import type { IQuestionTypeConfig } from "../models/question-type-config.model";

import {
  setEditorCode,
  setQuestionAnswer,
  setQuestionStatus,
  toggleEditorVisibility,
  toggleRestoreDefaultQuestionModal,
} from "./state/technical.actions";
import type { ITechnicalQuestionReducerState } from "./state/technical.reducer";
import { useTechnicalQuestionReducer } from "./state/technical.reducer";

interface ITechnicalProps {
  interviewId: string;
  question: ITechnicalQuestion;
}

type TTechnicalAnswerRequest = Omit<ITechnicalQuestionAnswer, "isAnswered">;

const SX_PROPS: Record<
  "noMargin" | "appBarQuestionName" | "appBarTabs",
  SxProps
> = {
  noMargin: {
    margin: "0 auto",
  },
  appBarQuestionName: {
    padding: 2,
    marginBottom: 1,
  },
  appBarTabs: {
    marginBottom: 1,
  },
};

/**
 * @description Component for Interviewer Technical Question
 */

export const InterviewerTechnicalQuestion: FunctionComponent<
  ITechnicalProps
> = ({ interviewId, question }) => {
  const initialState: ITechnicalQuestionReducerState = {
    questionStatus: "SAVED",
    isEditorHidden: true,
    showRestoreQuestionModal: false,

    editorCode: question.code ?? "",
    questionAnswer: question.value ?? -1,
  };

  const [state, dispatch] = useTechnicalQuestionReducer(initialState);
  const [selectedTab, setSelectedTab] = useState<number | null>(null);

  const {
    questionStatus,
    showRestoreQuestionModal,
    editorCode,
    isEditorHidden,
    questionAnswer,
  } = state;

  const {
    id: questionId,
    name: questionName,
    codeBase: codeConfiguration,
  } = question;

  const programmingLanguageName = codeConfiguration?.name;
  const defaultQuestion = question.description;
  const editorFilePath = `question_${questionId}.${codeConfiguration?.fileExtension}`;

  /**
   * Used for identifying YJS document in server.
   */
  const codeEditorDocumentId = `interview:${interviewId}-question:${questionId}`;

  /**
   * Forwarded ref from Code Editor Component
   */
  const codeEditorRef = useRef<ICodeEditorRefAPI>();

  const { QuestionsTable } = useIndexedDB();
  const socket = useSocket();

  const restoreDefaultQuestionCode = () => {
    codeEditorRef.current?.handleRestoreDefaultQuestion();
  };

  const handleRestoreDefaultQuestionCode = () => {
    dispatch(toggleRestoreDefaultQuestionModal());
  };

  const handleDenyRestoreDefaultQuestion = () => {
    dispatch(toggleRestoreDefaultQuestionModal());
  };

  const handleConfirmRestoreDefaultQuestion = () => {
    restoreDefaultQuestionCode();
    dispatch(toggleRestoreDefaultQuestionModal());
  };

  const handleToggleEditor = () => {
    setSelectedTab(0);
    dispatch(toggleEditorVisibility());
  };

  const handleSubmit = async ({
    code,
    value,
  }: {
    code: string;
    value: number;
  }) => {
    dispatch(setQuestionStatus("SAVING"));

    const answer: TTechnicalAnswerRequest = {
      id: question.id,
      skillId: question.skillId,
      category: question.category,
      question: question.name,
      type: question.type,
      pastValue: question.value,
      value: value,
      pastCode: question.code,
      code: code,
    };

    socket.emit("ANSWER_UPDATE", answer);
  };

  const submitStateToServer = useCallbackRef(handleSubmit);

  const onCodeEditorDocumentChange = (event: YTextEvent) => {
    const localEditorCode = event.target.toString();

    submitStateToServer.current({
      code: localEditorCode,
      value: questionAnswer,
    });

    dispatch(setEditorCode(localEditorCode));
  };

  const debouncedOnCodeEditorDocumentChange = useDebounce(
    onCodeEditorDocumentChange,
    350
  );

  /**
   * USED FOR HANDLING SOCKET EVENTS
   */
  const handleAnswerUpdateSuccess = useCallback<
    TSocketSuccessHandler<ITechnicalQuestionAnswer>
  >(
    async ({ data }) => {
      //Triggers Global Display state re render.
      await QuestionsTable.update(data.id, data);

      setTimeout(() => {
        dispatch(setQuestionStatus("SAVED"));
      }, 650);
    },
    [QuestionsTable, dispatch]
  );

  const handleUpdateError = useCallback<
    TSocketFailureHandler<ITechnicalQuestionAnswer>
  >(
    async (error) => {
      const { data: answer } = error;

      dispatch(setQuestionStatus("ERROR_SAVING"));

      if (answer) {
        dispatch(setQuestionAnswer(answer.value));
        dispatch(setEditorCode(answer.code));
        await QuestionsTable.update(questionId, answer);
      } else {
        dispatch(setQuestionAnswer(question.pastValue));
        dispatch(setEditorCode(question.pastCode));
        await QuestionsTable.update(questionId, {
          value: question.value,
          code: question.code,
        });
      }

      setTimeout(() => {
        dispatch(setQuestionStatus("SAVED"));
      }, 3000);
    },
    [
      QuestionsTable,
      dispatch,
      question.code,
      question.pastCode,
      question.pastValue,
      question.value,
      questionId,
    ]
  );

  const handleExternalAnswerUpdate = useCallback<
    TSocketSuccessHandler<ITechnicalQuestionAnswer>
  >(
    async ({ data: answer }) => {
      dispatch(setQuestionStatus("EXTERNAL_UPDATE"));
      dispatch(setQuestionAnswer(answer.value));
      dispatch(setEditorCode(answer.code));

      await QuestionsTable.update(questionId, answer);

      setTimeout(() => {
        dispatch(setQuestionStatus("SAVED"));
      }, 650);
    },
    [QuestionsTable, dispatch, questionId]
  );

  useSocketAnswerEvents(questionId, {
    onAnswerUpdateSuccess: handleAnswerUpdateSuccess,
    onUpdateError: handleUpdateError,
    onExternalAnswerUpdate: handleExternalAnswerUpdate,
  });

  const handleQuestionSatisfactionChange = (value: string) => {
    const satisfaction = parseInt(value, 10);

    dispatch(setQuestionAnswer(satisfaction));

    handleSubmit({
      code: editorCode,
      value: satisfaction,
    });
  };

  if (!question)
    return (
      <Grid container xs={12} md={8} xl={7} sx={SX_PROPS.noMargin}>
        <Alert severity="info">
          <strong>
            <i className="fas fa-spinner fa-spin"></i>
          </strong>
          <br />
          Something went wrong loading the question.
        </Alert>
      </Grid>
    );
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={8} xl={7}>
        <ConfirmationModal
          isOpen={showRestoreQuestionModal}
          question="Are you sure you want to restore the default question?"
          onDeny={handleDenyRestoreDefaultQuestion}
          onConfirm={handleConfirmRestoreDefaultQuestion}
        />

        <QuestionStatus questionStatus={questionStatus} />
        <AppBar
          position="relative"
          color="transparent"
          sx={SX_PROPS.appBarQuestionName}
        >
          <Typography variant="body1">{questionName}</Typography>
        </AppBar>

        <AppBar
          position="relative"
          color="transparent"
          sx={SX_PROPS.appBarTabs}
        >
          <Tabs
            value={selectedTab === null ? false : selectedTab}
            onChange={(event, newValue) => setSelectedTab(newValue)}
          >
            <Tab
              focusRipple={true}
              label="Toggle Editor"
              onClick={handleToggleEditor}
            />
            <Tab label={programmingLanguageName} />
            <Tab
              label="Restore Default"
              onClick={handleRestoreDefaultQuestionCode}
            />
          </Tabs>
        </AppBar>

        <Grid item xs={12} boxShadow={1}>
          {!isEditorHidden && (
            <CodeEditor
              ref={codeEditorRef as Ref<ICodeEditorRefAPI>}
              socketRoomId={interviewId}
              editorDefaultQuestion={defaultQuestion}
              editorDocumentId={codeEditorDocumentId}
              editorFilePath={editorFilePath}
              editorHeight="400px"
              isEditorHidden={isEditorHidden}
              handleCodeEditorChange={debouncedOnCodeEditorDocumentChange}
            />
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} md={4} xl={5}>
        <QuestionSatisfaction
          value={questionAnswer}
          onChange={handleQuestionSatisfactionChange}
        />
      </Grid>
    </Grid>
  );
};

// Useful configuration for mapping data related to this question type
export const TECHNICAL_QUESTION_CONFIG: IQuestionTypeConfig = {
  label: "Technical Test",
  options: SATISFACTION_SCALE_OPTIONS,
};

export default InterviewerTechnicalQuestion;
