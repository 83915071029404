import { useReducer } from "react";

import type { Reducer } from "react";

import { generateStateUpdater } from "@common/utils/reducers/reducers.util";

import type { TQuestionState } from "../../../question-status/question-status.component";

import type { TTextActions } from "./text.actions";
import { ETextActionTypes } from "./text-actions.types";

export interface ITextReducerState {
  questionStatus: TQuestionState;
  questionComment: string;
  questionAnswer: number;
  activeTab: string | false;
}

export const textQuestionReducer: Reducer<ITextReducerState, TTextActions> = (
  state,
  action
) => {
  const updateState = generateStateUpdater(state);

  switch (action.type) {
    case ETextActionTypes.SET_QUESTION_STATUS: {
      return updateState({
        questionStatus: action.payload,
      });
    }

    case ETextActionTypes.SET_QUESTION_COMMENT: {
      return updateState({
        questionComment: action.payload,
      });
    }

    case ETextActionTypes.SET_QUESTION_ANSWER: {
      return updateState({
        questionAnswer: action.payload,
      });
    }

    case ETextActionTypes.SET_ACTIVE_TAB: {
      return updateState({
        activeTab: action.payload,
      });
    }

    default:
      return state;
  }
};

export const useTextQuestionReducer = (initialState: ITextReducerState) => {
  return useReducer(textQuestionReducer, initialState);
};
