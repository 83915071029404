import { useCallback, useState } from "react";

import { Alert, Grid, Paper } from "@mui/material";
import type { FunctionComponent } from "react";

import { useSocket } from "@common/contexts/socket-io/socket-io.component";
import { useIndexedDB } from "@common/contexts/web-storage/indexed-db.context";
import type { IYesNoQuestionAnswer } from "@common/models/answer/answer.model";
import type { IYesNoQuestion } from "@common/models/question/question.model";

import type { TQuestionState } from "../../question-status/question-status.component";
import { QuestionStatus } from "../../question-status/question-status.component";
import type { TQuestionScaleValues } from "../../scales/components/questions-scale/question-scale.component";
import { QuestionScale } from "../../scales/components/questions-scale/question-scale.component";
import convertValuesToOptions from "../../scales/utils/convert-values-to-options";
import type {
  TSocketFailureHandler,
  TSocketSuccessHandler,
} from "../hooks/use-socket-answer-events/use-socket-answer-events.hook";
import { useSocketAnswerEvents } from "../hooks/use-socket-answer-events/use-socket-answer-events.hook";
import type { IQuestionTypeConfig } from "../models/question-type-config.model";

interface IScaleProps {
  question: IYesNoQuestion;
}

type TYesNoQuestionAnswerRequest = Omit<IYesNoQuestionAnswer, "isAnswered">;

const YES_NO_OPTIONS: TQuestionScaleValues = new Map([
  ["na", "Doesn't apply"],
  ["yes", "Yes"],
  ["no", "No"],
]);

const options = convertValuesToOptions(YES_NO_OPTIONS);

export const YesNo: FunctionComponent<IScaleProps> = ({ question }) => {
  const { QuestionsTable } = useIndexedDB();
  const socket = useSocket();
  const [questionStatus, setQuestionStatus] = useState<TQuestionState>("SAVED");
  const [questionAnswer, setQuestionAnswer] = useState<string>(question.value);

  const { id: questionId, name: questionName } = question;

  /**
   * USED FOR HANDLING SOCKET EVENTS
   */
  const handleAnswerUpdateSuccess = useCallback<
    TSocketSuccessHandler<IYesNoQuestionAnswer>
  >(
    async ({ data }) => {
      await QuestionsTable.update(data.id, data);

      setTimeout(() => {
        setQuestionStatus("SAVED");
      }, 650);
    },
    [QuestionsTable]
  );

  const handleUpdateError = useCallback<
    TSocketFailureHandler<IYesNoQuestionAnswer>
  >(
    async (error) => {
      const { data: answer } = error;

      setQuestionStatus("ERROR_SAVING");

      if (answer) {
        setQuestionAnswer(answer.value);
        await QuestionsTable.update(questionId, answer);
      } else {
        setQuestionAnswer(question.pastValue);
        await QuestionsTable.update(questionId, {
          value: question.pastValue,
        });
      }

      setTimeout(() => {
        setQuestionStatus("SAVED");
      }, 3000);
    },
    [QuestionsTable, question.pastValue, questionId]
  );

  const handleExternalAnswerUpdate = useCallback<
    TSocketSuccessHandler<IYesNoQuestionAnswer>
  >(
    async ({ data: answer }) => {
      setQuestionAnswer(answer.value);
      setQuestionStatus("EXTERNAL_UPDATE");

      await QuestionsTable.update(questionId, answer);

      setTimeout(() => {
        setQuestionStatus("SAVED");
      }, 500);
    },
    [QuestionsTable, questionId]
  );

  useSocketAnswerEvents(questionId, {
    onAnswerUpdateSuccess: handleAnswerUpdateSuccess,
    onUpdateError: handleUpdateError,
    onExternalAnswerUpdate: handleExternalAnswerUpdate,
  });

  const handleSubmit = (newValue: string) => {
    setQuestionStatus("SAVING");

    const answer: TYesNoQuestionAnswerRequest = {
      id: question.id,
      skillId: question.skillId,
      category: question.category,
      question: question.name,
      type: question.type,
      pastValue: question.value,
      value: newValue,
    };

    socket.emit("ANSWER_UPDATE", answer);
  };

  const handleScaleChange = (value: string) => {
    handleSubmit(value);
    setQuestionAnswer(value);
  };

  if (!question) {
    return (
      <Grid container xs={12} md={8} xl={7} sx={{ margin: "0 auto" }}>
        <Alert color="info">
          <p>
            <strong>
              <i className="fas fa-spinner fa-spin"></i>
            </strong>
            <br />
            This question has not been loaded yet.
          </p>
        </Alert>
      </Grid>
    );
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={8} xl={7} marginBottom={2.5}>
        <QuestionStatus questionStatus={questionStatus} />
        <Paper elevation={5}>
          <div className="is-fullwidth p-4">{questionName}</div>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4} xl={5}>
        <QuestionScale
          title="Yes / No"
          options={options}
          value={questionAnswer}
          onChange={handleScaleChange}
        />
      </Grid>
    </Grid>
  );
};

// Useful configuration for mapping data related to this question type
export const YES_NO_QUESTION_CONFIG: IQuestionTypeConfig = {
  label: "Yes/No",
  options: YES_NO_OPTIONS,
};

export default YesNo;
