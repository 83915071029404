import React from "react";

import { InternalServerError500 } from "@screens/public/errors/500/500.screen";

interface IErrorBoundaryProps {
  children?: React.ReactNode;
}

interface IErrorBoundaryState {
  hasErrored: boolean;
}

export class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: Readonly<IErrorBoundaryProps>) {
    super(props);

    this.state = {
      hasErrored: false,
    };
  }

  public clearError(this: ErrorBoundary) {
    this.setState({
      hasErrored: false,
    });
  }

  public static getDerivedStateFromError(): IErrorBoundaryState {
    return { hasErrored: true };
  }

  render() {
    if (this.state.hasErrored === false) {
      return this.props.children;
    }

    return (
      <InternalServerError500
        clearErrorFromErrorBoundary={this.clearError.bind(this)}
      />
    );
  }
}
