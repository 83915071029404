import type { TNonDynamicQuestionType } from "@common/models/question/question.model";

import type { IQuestionTypeConfig } from "../models/question-type-config.model";
import { SCALE_QUESTION_CONFIG } from "../scale/scale.component";
import { TECHNICAL_QUESTION_CONFIG } from "../technical/technical.component";
import { TEXT_QUESTION_CONFIG } from "../text/text.component";
import { YES_NO_QUESTION_CONFIG } from "../yes-no/yes-no.component";

export const QUESTION_TYPES_CONFIG: Record<
  TNonDynamicQuestionType,
  IQuestionTypeConfig
> = {
  Scale: SCALE_QUESTION_CONFIG,
  Text: TEXT_QUESTION_CONFIG,
  Technical: TECHNICAL_QUESTION_CONFIG,
  YesNo: YES_NO_QUESTION_CONFIG,
};
