import { createContext, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import type { User } from "@auth0/auth0-react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import type { FunctionComponent, ReactNode } from "react";

import { LoadingLogo } from "@common/components/loading-logo/loading-logo.component";
import { isUserInterviewer } from "@common/utils/validators/auth-validators/auth-validator";

interface IAuthContext {
  user: User | undefined;
  error: Error | undefined;
  isInterviewer: boolean;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

interface IProtectRouteProps {
  children: ReactNode;
}

const ProtectedRoute: FunctionComponent<IProtectRouteProps> = ({
  children,
}) => {
  const { user, error } = useAuth0();
  const isInterviewer = isUserInterviewer(user);

  const authContext: IAuthContext = {
    user,
    error,
    isInterviewer,
  };

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export const useAuthRoute = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuthRoute must be used within a AuthProvider");
  }

  return context;
};

/**
 * @description - This element protects routes by checking user role if it's authorized to access the route.
 * This should be used as a Route element with sub routes to be protected
 */
export const RestrictTo: FunctionComponent<{ allowedRoles: string[] }> = ({
  allowedRoles,
}) => {
  if (!(allowedRoles instanceof Array) || allowedRoles.length === 0) {
    throw new Error("allowedRoles must be an array with at least one role");
  }

  const { user } = useAuthRoute();

  const roles = user?.["https://cecropiasolutions.com/roles"] as string[];

  return roles?.some((role) => allowedRoles.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate
      to="/interview/not-found"
      state={{
        reason: {
          message: "It seems that you are not authorized to access this page.",
          subtitle: "We believe you are lost. Please return to the dashboard.",
        },
      }}
    />
  );
};

export default withAuthenticationRequired(ProtectedRoute, {
  onRedirecting: () => <LoadingLogo />,
});
