import { FormControl, FormLabel } from "@mui/material";
import type { ChangeEvent, FunctionComponent } from "react";

import type { IScaleOption } from "@common/components/scale-factory/scale-factory.component";
import { ScaleFactory } from "@common/components/scale-factory/scale-factory.component";

export type TQuestionScaleValues = Readonly<Map<number | string, string>>;

interface IQuestionScaleProps {
  //Props
  title: string;
  value: unknown;
  options: IScaleOption[];

  //Methods
  onChange: (value: string) => void | Promise<void>;
}

/**
 * Question Scale Factory
 */
export const QuestionScale: FunctionComponent<IQuestionScaleProps> = ({
  title,
  value,
  options,

  onChange,
}) => {
  const handleScaleChange = (
    _: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    onChange(value);
  };

  return (
    <FormControl>
      <FormLabel>{title}</FormLabel>

      <ScaleFactory
        row
        value={value}
        options={options}
        onChange={handleScaleChange}
      />
    </FormControl>
  );
};
