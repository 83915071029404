import type { TQuestion } from "@common/models/question/question.model";

export const getAnswerScorePercentage = (
  answers: TQuestion[],
  perAnswerValue: number
) => {
  const percentageReducer = (percentage: number, answer: TQuestion) =>
    (Number(answer.value) * perAnswerValue) / answers.length + percentage;

  return answers.reduce(percentageReducer, 0);
};
