import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import type { ISocketEvent } from "@common/contexts/socket-io/hooks/use-handle-socket-events/use-handle-socket.events.hook";
import { useHandleSocketEvents } from "@common/contexts/socket-io/hooks/use-handle-socket-events/use-handle-socket.events.hook";
import { useSocket } from "@common/contexts/socket-io/socket-io.component";
import { useIndexedDB } from "@common/contexts/web-storage/indexed-db.context";
import useDexieDBCleaner from "@common/hooks/dexie/use-dexie-db-cleaner/use-dexie-db-cleaner.hook";
import type {
  ISocketError,
  ISocketSuccess,
} from "@common/models/socket/socket.model";
import type { IDisconnectInterviewClosedResponse } from "@common/models/socket/socket-events/interview/disconnect-interview-closed.model";
import { useAuthRoute } from "@routers/protected/protected.router";

import { useInterview } from "../../interview.screen";
import type { IIntervieweeJoinResponse } from "../../screens/loading/models/socket-events/interviewee-join-response.model";
import type { IInterviewerInterviewJoinResponse } from "../../screens/loading/models/socket-events/interviewer-join-response.model";

export const useInterviewLoader = (interviewId: string): boolean => {
  useDexieDBCleaner();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const { isInterviewer } = useAuthRoute();

  const { QuestionsTable, ProfilesTable, SkillsTable } = useIndexedDB();

  const { setProfileOptions, setSkillOptions, setInterview } = useInterview();

  const socket = useSocket();

  const events = useMemo<ISocketEvent[]>(
    () => [
      {
        type: "once",
        name: "INTERVIEWER_JOIN_RESPONSE",
        handler: async ({
          data,
        }: ISocketSuccess<IInterviewerInterviewJoinResponse>) => {
          try {
            const { interview, availableProfiles, availableSkills } = data;

            setProfileOptions(availableProfiles);
            setSkillOptions(availableSkills);
            setInterview(interview);

            const skillsTableBulkPut = SkillsTable.bulkPut(
              interview.interview_results.selectedSkills
            );

            const profilesTableBulkPut = ProfilesTable.bulkPut(
              interview.interview_results.selectedProfiles
            );

            await Promise.all([skillsTableBulkPut, profilesTableBulkPut]);

            setIsLoading(false);
          } catch (error) {
            navigate("/500");
          }
        },
      },

      {
        type: "once",
        name: "INTERVIEWEE_JOIN_RESPONSE",
        handler: async ({ data }: ISocketSuccess<IIntervieweeJoinResponse>) => {
          const { interview, technicalQuiz } = data;
          setInterview(interview);

          await QuestionsTable.bulkPut(technicalQuiz);

          setIsLoading(false);
        },
      },

      {
        type: "once",
        name: "DISCONNECT_INTERVIEW_NOT_READY",
        handler: (response: ISocketError) => {
          navigate("/interview/not-found", {
            state: {
              reason: response,
            },
          });
        },
      },

      {
        type: "once",
        name: "DISCONNECT_INTERVIEW_CLOSED_ERROR",
        handler: ({
          title,
          message,
          data,
        }: ISocketError<IDisconnectInterviewClosedResponse>) => {
          const state = {
            title,
            message,
            issuedInterview: data,
          };

          navigate(`/interview/${interviewId}/closed`, {
            state,
          });
        },
      },
      {
        type: "once",
        name: "DISCONNECT_INTERVIEW_NOTFOUND",
        handler: (error: ISocketError<void>) => {
          const state = {
            reason: error,
          };

          navigate("/interview/not-found", {
            state,
          });
        },
      },
      {
        type: "once",
        name: "DISCONNECT_NO_PERMISSION",
        handler: ({ title, message }: ISocketError<void>) => {
          const state = {
            reason: {
              title,
              message,
              code: "USER_NAVIGATE_WITHOUT_CHART",
            },
          };

          navigate("/interview/not-found", {
            state,
          });
        },
      },
    ],
    [
      ProfilesTable,
      QuestionsTable,
      SkillsTable,
      interviewId,
      navigate,
      setInterview,
      setProfileOptions,
      setSkillOptions,
    ]
  );

  //Listens for changes on location state for interview
  useEffect(() => {
    if (!interviewId) {
      return;
    }

    const joinEvent = isInterviewer ? "INTERVIEWER_JOIN" : "INTERVIEWEE_JOIN";

    socket.emit(joinEvent, interviewId);
  }, [interviewId, isInterviewer, socket]);

  useHandleSocketEvents(events);

  return isLoading;
};

export default useInterviewLoader;
