import { useCallback, useMemo, useState } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import type { Collection } from "dexie";
import { useLiveQuery } from "dexie-react-hooks";
import type { FunctionComponent } from "react";

import { LoadingDroplets } from "@common/components/loading-droplets/loading-droplets.component";
import type { ISkillDB } from "@common/contexts/web-storage/indexed-db.context";
import { useIndexedDB } from "@common/contexts/web-storage/indexed-db.context";
import type {
  ICustomOptionsQuestion,
  IScaleQuestion,
  ITechnicalQuestion,
  ITextQuestion,
  IYesNoQuestion,
  TNonDynamicQuestion,
  TQuestion,
} from "@common/models/question/question.model";
import { SlideUp } from "@common/utils/components/react-mui/transitions/slide-up/slide-up.comonent";

import { AnswerDetailCard } from "./components/answer-detail/answer-detail.component";
import { NoQuestionsFoundAlert } from "./components/no-questions-found-alert/no-questions-found-alert.component";
import { NonDynamicAnswerDetail } from "./components/non-dynamic-answer-detail/non-dynamic-answer-detail.component";
import { AnswerTracker } from "./components/rating/answer-tracker/answer-tracker.component";
import { AnswersScore } from "./components/rating/score/score.component";

import "./review-modal.styles.css";

const SX_PROPS = {
  reviewModalStyle: {
    p: 5,
    height: "85vh",
  },
  alertModalStyle: {
    display: "flex",
    alignItems: "center",
  },
};

interface IReviewStep {
  stepTitle: string;
  stepLabel: string;
  stepContent: JSX.Element;
}

interface IReviewModalProps {
  isLoadingData?: boolean;
  children?: never;
  showModal: boolean;
  onClose: () => void;
}

export const ReviewModal: FunctionComponent<IReviewModalProps> = ({
  isLoadingData = false,
  showModal,
  onClose,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const mediaQuery = useMediaQuery("(max-width:768px)");

  const { QuestionsTable, SkillsTable } = useIndexedDB();
  // IndexDB queries
  const scaleQuestionsQuery = () =>
    QuestionsTable.where("type").equals("Scale");
  const textQuestionsQuery = () => QuestionsTable.where("type").equals("Text");
  const technicalQuestionsQuery = () =>
    QuestionsTable.where("type").equals("Technical");
  const customOptionsQuestionsQuery = () =>
    QuestionsTable.where("type").equals("CustomOptions");
  const yesNoQuestionsQuery = () =>
    QuestionsTable.where("type").equals("YesNo");
  const allSkillsQuery = () => SkillsTable.toArray();

  //Questions
  const scaleQuestions = useLiveQuery(
    () => scaleQuestionsQuery().reverse().sortBy("answer"),
    [],
    []
  ) as IScaleQuestion[];
  const textQuestions = useLiveQuery(
    () => textQuestionsQuery().reverse().sortBy("answer"),
    [],
    []
  ) as ITextQuestion[];
  const technicalTestQuestions = useLiveQuery(
    () => technicalQuestionsQuery().reverse().sortBy("answer"),
    [],
    []
  ) as ITechnicalQuestion[];
  const customTypesQuestions = useLiveQuery(
    () => customOptionsQuestionsQuery().reverse().sortBy("answer"),
    [],
    []
  ) as ICustomOptionsQuestion[];

  const yesNoQuestions = useLiveQuery(
    () => yesNoQuestionsQuery().reverse().sortBy("answer"),
    [],
    []
  ) as IYesNoQuestion[];

  const answerScoreAboveZeroQuery = useCallback(
    (collection: Collection<TQuestion, string>) =>
      collection.and(({ value }) => Number(value) > 0).toArray(),
    []
  );

  const scaleQuestionsByAnswerAboveZero = useLiveQuery(
    () => answerScoreAboveZeroQuery(scaleQuestionsQuery()),
    [],
    []
  ) as IScaleQuestion[];

  const textQuestionsByAnswerAboveZero = useLiveQuery(
    () => answerScoreAboveZeroQuery(textQuestionsQuery()),
    [],
    []
  ) as ITechnicalQuestion[];

  const technicalTestQuestionsByAnswerAboveZero = useLiveQuery(
    () => answerScoreAboveZeroQuery(technicalQuestionsQuery()),
    [],
    []
  ) as ITechnicalQuestion[];

  const skills = useLiveQuery(allSkillsQuery, [], [] as ISkillDB[]);

  const skillsById = useMemo(() => {
    const skillsByIdMap = new Map<string, ISkillDB>();

    skills.forEach((skill) => {
      skillsByIdMap.set(skill.Id, skill);
    });

    return skillsByIdMap;
  }, [skills]);

  const nonDynamicQuestionsMapperFunction = useCallback(
    ({ skillId, type, value, id, name: question }: TNonDynamicQuestion) => (
      <NonDynamicAnswerDetail
        key={id}
        skillId={skillId}
        type={type}
        answer={value}
        question={question}
        skillsMap={skillsById}
      />
    ),
    [skillsById]
  );

  const handleBackButton = () =>
    setCurrentStep(currentStep === 0 ? currentStep : currentStep - 1);

  const handleNextButton = () => {
    if (currentStep >= reviewSteps.length - 1) {
      setCurrentStep(0);
      onClose();

      return;
    }

    setCurrentStep(currentStep + 1);
  };

  const reviewSteps: IReviewStep[] = [
    {
      stepTitle: "Scale Questions",
      stepLabel: "Scale Questions",
      stepContent: (
        <>
          {scaleQuestions.map(nonDynamicQuestionsMapperFunction)}
          {!scaleQuestions.length && <NoQuestionsFoundAlert />}
        </>
      ),
    },

    {
      stepTitle: "Text Questions",
      stepLabel: "Text Questions",
      stepContent: (
        <>
          {textQuestions.map(nonDynamicQuestionsMapperFunction)}
          {!textQuestions.length && <NoQuestionsFoundAlert />}
        </>
      ),
    },
    {
      stepTitle: "Technical Questions",
      stepLabel: "Technical Questions",
      stepContent: (
        <>
          {technicalTestQuestions.map(nonDynamicQuestionsMapperFunction)}
          {!technicalTestQuestions.length && <NoQuestionsFoundAlert />}
        </>
      ),
    },

    {
      stepTitle: "Custom Options Questions (Non-Rated)",
      stepLabel: "Custom Options Questions",
      stepContent: (
        <>
          {customTypesQuestions.map(
            ({ id, skillId, name: question, value, options }) => {
              const skillName = skillsById.get(skillId)?.Name ?? "N/A";

              const optionById = options.reduce((acc, option) => {
                acc.set(option.value, option.label);

                return acc;
              }, new Map<string, string>());

              const questionAnswer = value
                .map((id) => optionById.get(id))
                .join(", ");

              return (
                <AnswerDetailCard
                  key={id}
                  skillName={skillName}
                  question={question}
                  answer={questionAnswer}
                />
              );
            }
          )}
          {!customTypesQuestions.length && <NoQuestionsFoundAlert />}
        </>
      ),
    },

    {
      stepTitle: "Yes/No Questions (Non-Rated)",
      stepLabel: "Yes/No Questions",
      stepContent: (
        <>
          {yesNoQuestions.map(nonDynamicQuestionsMapperFunction)}
          {!yesNoQuestions.length && <NoQuestionsFoundAlert />}
        </>
      ),
    },

    {
      stepTitle: "Global Rating",
      stepLabel: "Global Rating",
      stepContent: (
        <>
          {scaleQuestions.length > 0 && (
            <div className="xci-review-global-progress-rating">
              <AnswersScore
                answerTypeLabel="Scale answers"
                scoreByAnswer={25}
                answers={scaleQuestionsByAnswerAboveZero}
              />

              <AnswerTracker
                answerTrackerLabel="Scale answers"
                answers={scaleQuestions}
              />
            </div>
          )}

          {textQuestions.length > 0 && (
            <div className="xci-review-global-progress-rating">
              <AnswersScore
                answerTypeLabel="Text answers"
                scoreByAnswer={20}
                answers={textQuestionsByAnswerAboveZero}
              />

              <AnswerTracker
                answerTrackerLabel="Text answers"
                answers={textQuestions}
              />
            </div>
          )}

          {technicalTestQuestions.length > 0 && (
            <div className="xci-review-global-progress-rating">
              <AnswersScore
                answerTypeLabel="Technical Test answers"
                scoreByAnswer={20}
                answers={technicalTestQuestionsByAnswerAboveZero}
              />

              <AnswerTracker
                answerTrackerLabel="Technical Test answers"
                answers={technicalTestQuestions}
              />
            </div>
          )}
        </>
      ),
    },
  ];

  const stepperOrientation = mediaQuery ? "vertical" : "horizontal";
  const stepButtonLabel =
    currentStep >= reviewSteps.length - 1 ? "Finish" : "Next";

  return (
    <Dialog
      aria-labelledby="review-modal"
      aria-describedby="review-modal"
      open={showModal}
      keepMounted={false}
      onClose={onClose}
      TransitionComponent={SlideUp}
      maxWidth="xl"
      fullWidth
    >
      <DialogContent dividers={true} sx={SX_PROPS.reviewModalStyle}>
        <Typography variant="h4" textAlign="center">
          Review
        </Typography>
        <hr />

        <div className="dialog-main-content-container">
          {isLoadingData ? (
            <LoadingDroplets className="loading-review-background-container" />
          ) : (
            <>
              <Stepper
                activeStep={currentStep}
                orientation={stepperOrientation}
              >
                {reviewSteps.map(({ stepTitle, stepLabel }) => (
                  <Step
                    key={stepTitle}
                    title={stepTitle}
                    className="xci-review-step-display"
                  >
                    <StepLabel>{stepLabel}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <div className="xci-review-container-steps">
                {reviewSteps.at(currentStep)?.stepContent}
              </div>

              <div className="xci-review-container-buttons">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleBackButton}
                >
                  Back
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNextButton}
                >
                  {stepButtonLabel}
                </Button>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
