import type { IdToken } from "@auth0/auth0-react";
import type { ManagerOptions, Socket, SocketOptions } from "socket.io-client";
import io from "socket.io-client";

const SOCKET_IO_DEFAULT_OPTIONS: Partial<ManagerOptions & SocketOptions> = {
  autoConnect: true,
  reconnection: true,
  reconnectionDelay: 3000,
  reconnectionDelayMax: 10000,
  reconnectionAttempts: 8,
  transports: ["polling", "websocket"],
};

class SocketIOConnectionManager {
  static #instance: SocketIOConnectionManager;
  #socket: Socket | undefined;

  private constructor() {
    if (SocketIOConnectionManager.#instance) {
      throw new Error("Cannot create a new instance of a singleton");
    }

    this.#socket = undefined;
  }

  public initSocketIO({ token, claims }: { token: string; claims: IdToken }) {
    if (this.#socket) {
      return this;
    }

    const socketOptions: Partial<ManagerOptions & SocketOptions> = {
      ...SOCKET_IO_DEFAULT_OPTIONS,
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
      query: {
        username: claims?.name,
      },
    };

    this.#socket = io("/", socketOptions);

    return this;
  }

  public disconnectSocketIO() {
    if (this.#socket) {
      this.#socket.removeAllListeners();
      this.#socket.disconnect();
      this.#socket = undefined;
    }
  }

  public static getInstance() {
    if (!SocketIOConnectionManager.#instance) {
      SocketIOConnectionManager.#instance = new SocketIOConnectionManager();
    }

    return SocketIOConnectionManager.#instance;
  }

  public get wsProtocol(): string {
    return process.env.NODE_ENV === "production" ? "wss" : "ws";
  }

  public get httpProtocol(): string {
    return process.env.NODE_ENV === "production" ? "https" : "http";
  }

  public get port(): string {
    if (process.env.REACT_APP_SERVER_URL_PORT === undefined) {
      throw new Error("Port is not defined");
    }

    return process.env.REACT_APP_SERVER_URL_PORT;
  }

  public get host(): string {
    return window.location.hostname;
  }

  public get wsURL(): string {
    return `${this.wsProtocol}://${this.host}:${this.port}`;
  }

  public get socketIOUri(): string {
    return `${this.httpProtocol}://${this.host}:${this.port}`;
  }

  public get socketConnection(): Socket {
    if (!this.#socket) {
      const ERROR_MESSAGE =
        "Socket.io instance not initialized, please use initSocketIO";

      throw new Error(ERROR_MESSAGE);
    }

    return this.#socket;
  }

  public get isSocketIOInitialized(): boolean {
    return !!this.#socket;
  }

  public get isSocketIOConnected(): boolean {
    return !!this.#socket?.connected;
  }
}

export default SocketIOConnectionManager;
