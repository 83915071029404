import { createContext, useCallback, useContext, useMemo } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import type { FunctionComponent, ReactNode } from "react";
import StatusCode from "status-code-enum";

import type {
  IInterviewType,
  IInterviewTypeOption,
  ISubmitCode,
} from "@common/models/interview/interview.model";
import type { TGetInterview } from "@common/models/interview-tool/interviews.definitions";
import type { TQuestion } from "@common/models/question/question.model";
import type { IResponse } from "@common/models/response/response.model";
import {
  fetchWithAuth,
  isHttpRequestException,
} from "@common/utils/fetch/fetch-with-auth.hoc";
import type { IQueryParam } from "@common/utils/urls/query-param-builder";
import buildQueryParams from "@common/utils/urls/query-param-builder";

import type {
  TCreateInterviewFn,
  TGetInterviewByIdFn,
  TGetInterviewTypeByIdFn,
  TGetInterviewTypesFn,
  TGetSkillQuestionsFn,
  TGetSkillQuestionsInBatchFn,
  TSubmitCodeFn,
} from "./interviews.service.definitions";

const BASEURL = `${process.env.REACT_APP_BACKEND_BASE_API_ROUTE}/${process.env.REACT_APP_BACKEND_API_INTERVIEWS_ROUTE}`;

interface IInterviewServiceProps {
  children: ReactNode;
}

interface IInterviewServiceContext {
  createInterview: TCreateInterviewFn;
  getInterviewById: TGetInterviewByIdFn;
  getInterviewTypes: TGetInterviewTypesFn;
  getInterviewTypeById: TGetInterviewTypeByIdFn;
  submitCode: TSubmitCodeFn;
  getSkillQuestions: TGetSkillQuestionsFn;
  getSkillQuestionsInBatch: TGetSkillQuestionsInBatchFn;
}

const InterviewServiceContext = createContext<
  IInterviewServiceContext | undefined
>(undefined);

export const InterviewService: FunctionComponent<IInterviewServiceProps> = ({
  children,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchWithToken = useCallback(fetchWithAuth(getAccessTokenSilently), []);

  const createInterview: TCreateInterviewFn = async ({ data: parameters }) => {
    const { data } = await fetchWithToken<IResponse<TGetInterview>>({
      url: BASEURL,
      requestOptions: {
        method: "POST",
        body: JSON.stringify(parameters),
      },
      validateStatusCode: (status) => status === StatusCode.SuccessCreated,
    });

    return data;
  };

  const getInterviewById: TGetInterviewByIdFn = async ({
    signal,
    data: interviewId,
  }) => {
    const { data } = await fetchWithToken<IResponse<TGetInterview>>({
      url: `${BASEURL}/${interviewId}`,
      requestOptions: {
        method: "GET",
        signal,
      },
      validateStatusCode: (status) => status === StatusCode.SuccessOK,
    });

    return data;
  };

  const getInterviewTypes: TGetInterviewTypesFn = async ({ signal }) => {
    const { data } = await fetchWithToken<IResponse<IInterviewTypeOption[]>>({
      url: `${BASEURL}/types`,
      requestOptions: {
        method: "GET",
        signal,
      },
      validateStatusCode: (status) => status === StatusCode.SuccessOK,
    });

    return data;
  };

  const getInterviewTypeById: TGetInterviewTypeByIdFn = async ({
    signal,
    data: parameters,
  }) => {
    const { initialForm, id } = parameters;

    const queryToBuild: IQueryParam[] = [
      {
        name: "initialForm",
        value: initialForm,
      },
    ];

    const query = buildQueryParams(queryToBuild);
    const url = `${BASEURL}/types/${id}${query}`;

    const { data } = await fetchWithToken<IResponse<IInterviewType>>({
      url,
      requestOptions: {
        method: "GET",
        signal,
      },
      validateStatusCode: (status) => status === StatusCode.SuccessOK,
    });

    return data;
  };

  const submitCode: TSubmitCodeFn = async ({ data: code, signal }) => {
    const payload: typeof code = {
      code: btoa(code.code),
      input: btoa(code.input),
      languageId: code.languageId,
    };

    const { data } = await fetchWithToken<IResponse<ISubmitCode>>({
      url: `${BASEURL}/code`,
      requestOptions: {
        method: "POST",
        body: JSON.stringify(payload),
        signal,
      },
      validateStatusCode: (status) => status === StatusCode.SuccessOK,
    });

    return data;
  };

  const getSkillQuestions: TGetSkillQuestionsFn = async ({ data, signal }) => {
    try {
      const { interviewId, skillId } = data;

      const { data: questions } = await fetchWithToken<IResponse<TQuestion[]>>({
        url: `${BASEURL}/${interviewId}/skills/${skillId}/questions`,
        requestOptions: {
          method: "GET",
          signal,
        },
        validateStatusCode: (status) => status === StatusCode.SuccessOK,
      });

      return questions;
    } catch (error) {
      if (
        isHttpRequestException(error) &&
        error.statusCode === StatusCode.ClientErrorNotFound
      ) {
        // TODO: This workaround until the SuiteCRM API is fixed.
        return [];
      }

      throw error;
    }
  };

  const getSkillQuestionsInBatch: TGetSkillQuestionsInBatchFn = async ({
    data: parameters,
    signal,
  }) => {
    const { interviewId, skillIds } = parameters;

    const getSkillQuestionsMapper = (skillId: string) =>
      getSkillQuestions({
        data: { interviewId, skillId },
        signal,
      });

    const batch = skillIds.map(getSkillQuestionsMapper);

    return Promise.all(batch);
  };

  const service = useMemo(
    () => ({
      createInterview,
      getInterviewById,
      getInterviewTypes,
      getInterviewTypeById,
      submitCode,
      getSkillQuestions,
      getSkillQuestionsInBatch,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <InterviewServiceContext.Provider value={service}>
      {children}
    </InterviewServiceContext.Provider>
  );
};

export const useInterviewService = () => {
  const context = useContext(InterviewServiceContext);

  if (context === undefined) {
    throw new Error(`InterviewServiceContext was not provided.
    Make sure your component is child of InterviewService.`);
  }

  return context;
};
