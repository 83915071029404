import type { FunctionComponent } from "react";

import type { ISkillDB } from "@common/contexts/web-storage/indexed-db.context";
import type { TNonDynamicQuestionType } from "@common/models/question/question.model";
import { QUESTION_TYPES_CONFIG } from "@screens/private/interview/screens/interviewer/screens/editor/components/skill-tab-switcher/components/questions/components/types/config/question-types";

import { AnswerDetailCard } from "../answer-detail/answer-detail.component";

interface INonDynamicAnswerDetailProps {
  skillId: string;
  type: TNonDynamicQuestionType;
  answer: unknown;
  question: string;
  skillsMap: Map<string, ISkillDB>;
}

export const NonDynamicAnswerDetail: FunctionComponent<
  INonDynamicAnswerDetailProps
> = ({ skillId, type, answer, question, skillsMap }) => {
  const skillName = skillsMap.get(skillId)?.Name ?? "";
  const { options } = QUESTION_TYPES_CONFIG[type];

  const questionAnswer = options.get(answer as string | number) ?? "";

  return (
    <AnswerDetailCard
      skillName={skillName}
      question={question}
      answer={questionAnswer}
    />
  );
};
