export interface IQueryParam {
  name: string;
  value: unknown;
}

/**
 * Builds a query parameter string from an array of TQueryParam objects.
 *
 * @param params - An array of TQueryParam objects.
 * @returns The formatted query parameters string.
 *
 * @example
 * // Example usage:
 * const paramsToBuild: TQueryParam[] = [
 *   { name: "initial_form", value: true },
 *   { name: "fields", value: fields.length > 0 ? fields.join(",") : undefined },
 * ];
 *
 * // Result:
 * // buildQueryParams(paramsToBuild) -> "?initial_form=true&fields=id,name"
 */
const buildQueryParams = (params: IQueryParam[]): string => {
  // Ensure params is an array
  if (!Array.isArray(params)) {
    return "";
  }

  /**
   * Filters out elements with no name or where the value is undefined.
   *
   * @param query - The query parameter object.
   * @return Returns true if the query is valid, false otherwise.
   */
  const queryFilter = (query: IQueryParam): boolean =>
    query && typeof query.name === "string" && query.value !== undefined;

  /**
   * Maps a TQueryParam object to a string representation.
   *
   * @param query - The query parameter object.
   * @return The formatted query parameter string.
   */
  const queryMapper = ({ name, value }: IQueryParam): string =>
    `${name}=${value}`;

  // Apply the filter and map functions
  const paramsToBuild = params.filter(queryFilter).map(queryMapper);

  // Return the formatted query parameters string
  return paramsToBuild.length === 0 ? "" : `?${paramsToBuild.join("&")}`;
};

export default buildQueryParams;
