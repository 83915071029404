import { Navigate, useLocation, useNavigate } from "react-router-dom";

import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { Button, Container, Typography } from "@mui/material";
import type { FunctionComponent } from "react";

import "../../no-interaction.styles.css";

export const NotFound: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (!location?.state?.reason) {
    return <Navigate to="/" />;
  }

  const handleReturnToDashboard = () => {
    navigate("/dashboard");
  };

  const { reason = {} } = location.state;
  const {
    message: errorMessage = "Something went wrong.",
    title: errorTitle = "Ooops!",
    subtitle: subTitleErrorMessage = "Please return to the dashboard.",
  } = reason;

  return (
    <Container className="xci-nointeraction-centered">
      <SentimentDissatisfiedIcon className="xci-nointeraction-icon" />
      <Typography variant="h2" component="h2" marginBottom={1}>
        {errorTitle}
      </Typography>

      <Typography variant="h5" component="h5" marginBottom={2}>
        {errorMessage}
      </Typography>

      <Typography variant="subtitle1" component="p">
        {subTitleErrorMessage}
      </Typography>

      <hr />

      <Button
        variant="contained"
        color="primary"
        onClick={handleReturnToDashboard}
        size="large"
      >
        Understood
      </Button>
    </Container>
  );
};

export default NotFound;
