import { Navigate, Route, Routes } from "react-router-dom";

import type { FunctionComponent, ReactNode } from "react";

import { SocketIOProvider } from "@common/contexts/socket-io/socket-io.component";
import { IndexedDBProvider } from "@common/contexts/web-storage/indexed-db.context";
import { InterviewService } from "@common/services/interviews/interviews-service.context";
import { Header } from "@layouts/header/header.component";
import MainLayout from "@layouts/main/main.component";
import { Dashboard } from "@screens/private/dashboard/dashboard.screen";
import { Interview } from "@screens/private/interview/interview.screen";

interface IPrivateMiddlewareProps {
  children: ReactNode;
}

const PrivateMiddleware: FunctionComponent<IPrivateMiddlewareProps> = ({
  children,
}) => {
  return (
    <SocketIOProvider>
      <IndexedDBProvider>
        <InterviewService>
          <Header />
          <MainLayout>{children}</MainLayout>
        </InterviewService>
      </IndexedDBProvider>
    </SocketIOProvider>
  );
};

const PrivateRouter: FunctionComponent = () => (
  <PrivateMiddleware>
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/interview" element={<Navigate replace to="/dashboard" />} />
      <Route path="/interview/*" element={<Interview />} />

      <Route path="/*" element={<Navigate replace to="/dashboard" />} />
    </Routes>
  </PrivateMiddleware>
);

export default PrivateRouter;
