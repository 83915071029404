import { useMemo } from "react";

import { debounce } from "@common/utils/functions/debounce/debounce";

import { useCallbackRef } from "../use-callback-ref/use-callback-ref.hook";

/**
 * Hook that debounces a given function
 * @param {function} fn - The function to be debounced
 * @param {number} debounceTime - The time in milliseconds to debounce the function
 * @return {function} - A debounced version of the input function
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <T extends (...args: any[]) => any>(
  fn: T,
  debounceTime: number
) => {
  const callbackRef = useCallbackRef(fn);

  return useMemo(() => {
    const cb = (...args: unknown[]) => {
      callbackRef.current?.(...args);
    };

    return debounce(cb, debounceTime);
  }, [callbackRef, debounceTime]);
};
