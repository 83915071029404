import type {
  TToastMessageQueue,
  TToastStackType,
} from "@common/components/toast-stack/toast-stack.definitions";

import { EToastStackAction } from "./toast-stack-actions.types";

export const addMessage = (message: TToastMessageQueue) => {
  return {
    type: EToastStackAction.ADD_MESSAGE,
    payload: message,
  } as const;
};

export const removeMessageById = (id: string) =>
  ({
    type: EToastStackAction.REMOVE_MESSAGE_BY_ID,
    payload: id,
  } as const);

export const removeAllMessages = () =>
  ({
    type: EToastStackAction.REMOVE_ALL_MESSAGES,
  } as const);

export const removeMessageByType = (type: TToastStackType) =>
  ({
    type: EToastStackAction.REMOVE_MESSAGE_BY_TYPE,
    payload: type,
  } as const);
