import type { User } from "@auth0/auth0-react";

export const isUserInterviewer = (user: User | undefined): boolean => {
  const INTERVIEWER_ROLES = ["interviewer"];

  const roles = user?.["https://cecropiasolutions.com/roles"] as
    | string[]
    | undefined;

  if (!(roles instanceof Array) || roles.length === 0) {
    return false;
  }

  return roles.some((userRole) => INTERVIEWER_ROLES.includes(userRole));
};
