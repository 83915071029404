import { Navigate, useSearchParams } from "react-router-dom";

import type { FunctionComponent } from "react";

interface ITriggerCallbackProps {
  ErrorComponent: JSX.Element;
  SuccessComponent: JSX.Element;
}

export const TriggerCallback: FunctionComponent<ITriggerCallbackProps> = ({
  ErrorComponent,
  SuccessComponent,
}) => {
  const [searchParams] = useSearchParams({});

  const error = searchParams.get("error");

  if (error) {
    return ErrorComponent;
  }

  const code = searchParams.get("code");

  if (code) {
    return SuccessComponent;
  }

  return <Navigate replace to="/login" />;
};
