import type { SvgIconComponent } from "@mui/icons-material";
import {
  CheckCircle,
  Dangerous,
  Info,
  WarningOutlined,
} from "@mui/icons-material";

import type { TToastStackType } from "./toast-stack.definitions";

export const SX_PROPS = {
  typeIconStyle: {
    marginRight: 1,
  },
  boxContainerStyle: {
    position: "fixed",
    padding: "10px",
    zIndex: 9999,
    bottom: 0,
    left: 0,
  },
  messageBoxStyle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "4px",
  },
  actionBoxStyle: {
    cursor: "pointer",
  },
} as const;

const iconsByType: Readonly<Record<TToastStackType, SvgIconComponent>> = {
  success: CheckCircle,
  danger: Dangerous,
  warning: WarningOutlined,
  information: Info,
  neutral: Info,
};

export const getTypeIcon = (type: TToastStackType) => {
  const Icon = iconsByType[type];

  if (Icon === undefined) {
    return null;
  }

  return <Icon sx={SX_PROPS.typeIconStyle} />;
};
