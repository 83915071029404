import { Alert, Box, Typography } from "@mui/material";
import type { FunctionComponent } from "react";

export const NoQuestionsFoundAlert: FunctionComponent = () => {
  return (
    <Box marginTop={2}>
      <Alert severity="info">
        <Typography variant="h5">No questions found.</Typography>
      </Alert>
    </Box>
  );
};
