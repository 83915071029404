import { useCallback } from "react";

import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import type {
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
} from "@mui/material";
import {
  Alert,
  Box,
  Grid,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import type {
  ChangeEvent,
  FocusEvent,
  FunctionComponent,
  SyntheticEvent,
} from "react";

import { useSocket } from "@common/contexts/socket-io/socket-io.component";
import { useIndexedDB } from "@common/contexts/web-storage/indexed-db.context";
import type { ITextQuestionAnswer } from "@common/models/answer/answer.model";
import type { ITextQuestion } from "@common/models/question/question.model";

import { QuestionStatus } from "../../question-status/question-status.component";
import {
  QuestionSatisfaction,
  SATISFACTION_SCALE_OPTIONS,
} from "../../scales/question-satisfaction-scale/question-satisfaction-scale.component";
import type {
  TSocketFailureHandler,
  TSocketSuccessHandler,
} from "../hooks/use-socket-answer-events/use-socket-answer-events.hook";
import { useSocketAnswerEvents } from "../hooks/use-socket-answer-events/use-socket-answer-events.hook";
import type { IQuestionTypeConfig } from "../models/question-type-config.model";

import {
  setActiveTab,
  setQuestionAnswer,
  setQuestionComment,
  setQuestionStatus,
} from "./state/text.actions";
import { useTextQuestionReducer } from "./state/text.reducer";

interface ITextProps {
  question: ITextQuestion;
}

type TTextQuestionAnswerRequest = Omit<ITextQuestionAnswer, "isAnswered">;

const ANSWER_TEXT_FIELD_INPUT_PROPS:
  | Partial<FilledInputProps>
  | Partial<OutlinedInputProps>
  | Partial<InputProps> = {
  startAdornment: (
    <InputAdornment position="start">
      <QuestionAnswerIcon />
    </InputAdornment>
  ),
};

export const Text: FunctionComponent<ITextProps> = ({ question }) => {
  const [state, dispatch] = useTextQuestionReducer({
    questionStatus: "SAVED",
    questionComment: question.comment,
    questionAnswer: question.value,
    activeTab: false,
  });

  const { QuestionsTable } = useIndexedDB();
  const socket = useSocket();

  const {
    id: questionId,
    name: questionName,
    description: questionDescription,
  } = question;

  const { questionStatus, questionComment, questionAnswer, activeTab } = state;

  /**
   * USED FOR HANDLING SOCKET EVENTS
   */
  const handleAnswerUpdateSuccess = useCallback<
    TSocketSuccessHandler<ITextQuestionAnswer>
  >(
    async ({ data }) => {
      //Triggers Global Display state re render.
      await QuestionsTable.update(data.id, data);

      setTimeout(() => {
        dispatch(setQuestionStatus("SAVED"));
      }, 250);
    },
    [QuestionsTable, dispatch]
  );

  const handleUpdateError = useCallback<
    TSocketFailureHandler<ITextQuestionAnswer>
  >(
    async (error) => {
      const { data: answer } = error;

      dispatch(setQuestionStatus("ERROR_SAVING"));

      if (answer) {
        dispatch(setQuestionComment(answer.comment));
        dispatch(setQuestionAnswer(answer.value));

        await QuestionsTable.update(questionId, answer);
      } else {
        dispatch(setQuestionComment(question.pastComment));
        dispatch(setQuestionAnswer(question.pastValue));

        await QuestionsTable.update(questionId, {
          comment: question.pastComment,
          value: question.pastValue,
        });
      }

      setTimeout(() => {
        dispatch(setQuestionStatus("SAVED"));
      }, 3000);
    },
    [
      QuestionsTable,
      dispatch,
      question.pastComment,
      question.pastValue,
      questionId,
    ]
  );

  const handleExternalAnswerUpdate = useCallback<
    TSocketSuccessHandler<ITextQuestionAnswer>
  >(
    async ({ data: answer }) => {
      dispatch(setQuestionStatus("EXTERNAL_UPDATE"));
      dispatch(setQuestionComment(answer.comment));
      dispatch(setQuestionAnswer(answer.value));

      await QuestionsTable.update(questionId, answer);

      setTimeout(() => {
        dispatch(setQuestionStatus("SAVED"));
      }, 500);
    },
    [QuestionsTable, dispatch, questionId]
  );

  useSocketAnswerEvents(questionId, {
    onAnswerUpdateSuccess: handleAnswerUpdateSuccess,
    onUpdateError: handleUpdateError,
    onExternalAnswerUpdate: handleExternalAnswerUpdate,
  });

  if (!question) {
    return (
      <Grid container xs={12} md={8} xl={7} sx={{ margin: "0 auto" }}>
        <Alert color="info">
          <p>
            <strong>
              <i className="fas fa-spinner fa-spin"></i>
            </strong>
            <br />
            This question has not been loaded yet.
          </p>
        </Alert>
      </Grid>
    );
  }

  const handleSubmit = ({
    comment: newComment,
    value: newValue,
  }: {
    comment: string;
    value: number;
  }) => {
    dispatch(setQuestionStatus("SAVING"));

    const answer: TTextQuestionAnswerRequest = {
      id: question.id,
      skillId: question.skillId,
      question: question.name,
      category: question.category,
      type: question.type,
      pastValue: question.value,
      value: newValue,
      pastComment: question.comment,
      comment: newComment,
    };

    socket.emit("ANSWER_UPDATE", answer);
  };

  const handleTabSelection = (event: SyntheticEvent, tabName: string) => {
    event.preventDefault();

    const tab = !tabName || tabName === activeTab ? "" : tabName;
    dispatch(setActiveTab(tab));
  };

  const handleCommentFieldBlur = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    const comment = event.target.value;

    handleSubmit({ comment, value: questionAnswer });
    dispatch(setQuestionComment(comment));
  };

  const handleCommentFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const comment = event.target.value;

    dispatch(setQuestionComment(comment));
  };

  const handleQuestionSatisfactionChange = (satisfaction: string) => {
    const questionAnswer = parseInt(satisfaction, 10);

    handleSubmit({ comment: questionComment, value: questionAnswer });
    dispatch(setQuestionAnswer(questionAnswer));
  };

  /**
   * ? Create a TextBox with the question.
   * Save Question ID and Skill ID to set the values in the answers.
   */
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={8} xl={7}>
        <QuestionStatus questionStatus={questionStatus} />
        <Paper elevation={5}>
          <div className="is-fullwidth p-4">{questionName}</div>
        </Paper>

        <Grid item xs={12} md={8} xl={7} marginBottom={2.5}>
          <Tabs value={activeTab} onChange={handleTabSelection}>
            <Tab label="Show Answer" value="toggle_answer" />
            <Tab label="Enter Comment" value="enter_comment" />
          </Tabs>

          <Box p={2}>
            {activeTab === "toggle_answer" && (
              <TextField
                placeholder="No answer"
                disabled
                label="Answer"
                variant="outlined"
                fullWidth
                multiline
                value={questionDescription}
                rows={4}
                InputProps={ANSWER_TEXT_FIELD_INPUT_PROPS}
              />
            )}
            {activeTab === "enter_comment" && (
              <TextField
                placeholder="Write the answer to the question, please. The answer could be written in multiple lines or left in blank."
                label="Comment"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                InputProps={ANSWER_TEXT_FIELD_INPUT_PROPS}
                value={questionComment}
                onChange={handleCommentFieldChange}
                onBlur={handleCommentFieldBlur}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4} xl={5}>
        <QuestionSatisfaction
          value={questionAnswer}
          onChange={handleQuestionSatisfactionChange}
        />
      </Grid>
    </Grid>
  );
};

// Useful configuration for mapping data related to this question type
export const TEXT_QUESTION_CONFIG: IQuestionTypeConfig = {
  label: "Text",
  options: SATISFACTION_SCALE_OPTIONS,
};
