import { Link } from "react-router-dom";

import type { LogoutOptions } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import type { FunctionComponent } from "react";

const SX_PROPS = {
  appBarToolBar: {
    height: "80px",
    display: "flex",
    justifyContent: "center",
  },
};

const LOGOUT_OPTIONS: LogoutOptions = {
  logoutParams: {
    returnTo: window.location.origin + "/logout",
  },
};

export const Header: FunctionComponent = () => {
  const { logout, user } = useAuth0();

  const handleLogout = () => {
    logout(LOGOUT_OPTIONS);
  };

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={SX_PROPS.appBarToolBar}
    >
      <Toolbar>
        <Box display="flex" alignItems="center">
          <Link to="/dashboard">
            <img
              alt="Cecropia Solutions Ltd Logo"
              src="/img/multiplied_logo_horizontal.png"
              width="150"
            />
          </Link>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          flexGrow={1}
          justifyContent="flex-end"
        >
          <Button color="inherit" component={Link} to="/dashboard">
            <span>
              <i className="fas fa-fw fa-columns"></i>
              Dashboard
            </span>
          </Button>
          <Button color="inherit">
            <span>
              <i className="fas fa-fw fa-user"></i>
              {user?.name}
            </span>
          </Button>
          <Button color="error" onClick={handleLogout}>
            <span>
              <i className="fas fa-fw fa-sign-out-alt"></i>
              Log Out
            </span>
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
