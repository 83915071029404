import { Fragment, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from "react-router-dom";

import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";
import { Container, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import type { QueryFunctionContext } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { FunctionComponent } from "react";

import { clearTables } from "@common/contexts/web-storage/hooks/use-dexie-db-cleaner/use-dexie-db-cleaner.hook";
import { useIndexedDB } from "@common/contexts/web-storage/indexed-db.context";
import { useInterviewService } from "@common/services/interviews/interviews-service.context";
import { useAuthRoute } from "@routers/protected/protected.router";

import { ReviewModal } from "../../components/review-modal/review-modal.component";

import "../../no-interaction.styles.css";

const ClosedScreen: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const { uuid: interviewId } = useParams() as Record<string, string>;

  const { isInterviewer } = useAuthRoute();

  const database = useIndexedDB();
  const [showReviewModal, setReviewModal] = useState<boolean>(false);

  const InterviewService = useInterviewService();

  const handleGetInterview = async ({ signal }: QueryFunctionContext) => {
    const { interview_results: interviewResults } =
      await InterviewService.getInterviewById({
        data: interviewId,
        signal,
      });

    await clearTables(database, [
      "ProfilesTable",
      "SkillsTable",
      "QuestionsTable",
    ]);

    const selectedSkillIds = interviewResults.selectedSkills.map(
      ({ Id }) => Id
    );

    const skillsQuestions = await InterviewService.getSkillQuestionsInBatch({
      data: {
        interviewId,
        skillIds: selectedSkillIds,
      },
      signal,
    });

    const questions = skillsQuestions.flat();

    const tableBulkPutsPromises = [
      database.ProfilesTable.bulkPut(interviewResults.selectedProfiles),
      database.SkillsTable.bulkPut(interviewResults.selectedSkills),
      database.QuestionsTable.bulkPut(questions),
    ];

    await Promise.all(tableBulkPutsPromises);

    return {
      interviewResults,
      questions,
    };
  };

  const { isSuccess: isGetClosedInterviewSuccessful } = useQuery({
    queryKey: ["getClosedInterview", interviewId],
    queryFn: handleGetInterview,
  });

  const title = location.state?.title ?? "Interview is closed";
  const message =
    location.state?.message ??
    "Interview is closed. You can no longer edit it.";

  useEffect(() => {
    if (navigationType !== "POP") {
      return;
    }

    interviewId
      ? navigate(`/interview/${interviewId}`)
      : navigate("/dashboard");
  }, [interviewId, navigate, navigationType]);

  const handleReturnToDashboard = () => {
    navigate("/dashboard");
  };

  const handleReviewModalOpen = () => {
    setReviewModal(true);
  };

  const handleReviewModalClose = () => {
    setReviewModal(false);
  };

  return (
    <>
      <Container className="xci-nointeraction-centered">
        <LockTwoToneIcon className="xci-nointeraction-icon" />

        <Typography variant="h4">{title}</Typography>

        <Typography variant="body1">{message}</Typography>

        {!isInterviewer ? null : (
          <Fragment>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReviewModalOpen}
            >
              Checkout this interview
            </Button>

            <hr />
          </Fragment>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleReturnToDashboard}
        >
          Return to dashboard
        </Button>
      </Container>

      {showReviewModal === true && (
        <ReviewModal
          showModal={showReviewModal}
          onClose={handleReviewModalClose}
          isLoadingData={!isGetClosedInterviewSuccessful}
        />
      )}
    </>
  );
};

export default ClosedScreen;
