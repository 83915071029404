import { type FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Button, Container, Typography } from "@mui/material";

import "../../no-interaction.styles.css";

/**
 * Loading screen component...
 */
export const Loading: FunctionComponent = () => {
  const navigate = useNavigate();

  const handleReturnToDashboardButton = () => {
    navigate("/dashboard");
  };

  return (
    <Container className="xci-nointeraction-centered">
      <CloudDownloadIcon className="xci-nointeraction-icon" />

      <Typography variant="h4" component="h2">
        Loading interview
      </Typography>

      <Typography variant="body1" component="p">
        Please wait while we load the interview.
      </Typography>

      <hr />

      <Button
        variant="contained"
        color="primary"
        onClick={handleReturnToDashboardButton}
      >
        Return to dashboard
      </Button>
    </Container>
  );
};

export default Loading;
