import type { FunctionComponent } from "react";

import { QuestionStatusStyled } from "./question-status.styles";

export type TQuestionState =
  | "SAVED"
  | "SAVING"
  | "WAITING"
  | "EXTERNAL_UPDATE"
  | "ERROR_SAVING"
  | "LOADING";

type TLoadingTransactionStates = {
  [x in TQuestionState as x]: {
    status: string;
    backgroundColor: string;
    color: string;
  };
};

// Different states of the question status
const LoadingTransactionStates: Readonly<TLoadingTransactionStates> = {
  SAVED: {
    status: "The answer is saved.",
    backgroundColor: "green",
    color: "white",
  },
  SAVING: {
    status: "Saving answer...",
    backgroundColor: "yellow",
    color: "black",
  },
  WAITING: {
    status: "Waiting for server approval.",
    backgroundColor: "skyblue",
    color: "black",
  },
  EXTERNAL_UPDATE: {
    status: "Someone is updating question.",
    backgroundColor: "yellow",
    color: "black",
  },
  ERROR_SAVING: {
    status: "Error while saving the answer.",
    backgroundColor: "red",
    color: "white",
  },
  LOADING: {
    status: "The question is loading",
    backgroundColor: "green",
    color: "white",
  },
};

interface IQuestionStatus {
  questionStatus: TQuestionState;
}

/**
 * Question status component
 * @description Component that shows the status of the question
 */
export const QuestionStatus: FunctionComponent<IQuestionStatus> = ({
  questionStatus,
}) => {
  const { status, backgroundColor, color } =
    LoadingTransactionStates[questionStatus];

  return (
    <QuestionStatusStyled $backgroundColor={backgroundColor} $color={color}>
      {status}
    </QuestionStatusStyled>
  );
};
