import type { FunctionComponent } from "react";

import type { TQuestionScaleValues } from "../components/questions-scale/question-scale.component";
import { QuestionScale } from "../components/questions-scale/question-scale.component";
import convertValuesToOptions from "../utils/convert-values-to-options";

interface IQuestionSatisfactionProps {
  value: unknown;

  onChange: (value: string) => void;
}

export const SATISFACTION_SCALE_OPTIONS: TQuestionScaleValues = new Map([
  [-1, "Doesn't apply"],
  [1, "Totally Disagree"],
  [2, "Disagree"],
  [3, "Neutral"],
  [4, "Agree"],
  [5, "Totally Agree"],
]);

const options = convertValuesToOptions(SATISFACTION_SCALE_OPTIONS);

export const QuestionSatisfaction: FunctionComponent<
  IQuestionSatisfactionProps
> = ({ value, onChange }) => {
  return (
    <QuestionScale
      title="Satisfaction"
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
