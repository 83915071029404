import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { FunctionComponent } from "react";

import { ErrorBoundary } from "@common/components/error-boundary/error-boundary.component";
import { PublicRouter } from "@routers/public/public.router";

const router = createBrowserRouter([
  {
    path: "/*",
    Component: PublicRouter,
  },
]);

const queryClient = new QueryClient();

const App: FunctionComponent = () => (
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </ErrorBoundary>
);

export default App;
