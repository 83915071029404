import { useEffect } from "react";

import type {
  IIndexDBContext,
  TTableName,
} from "@common/contexts/web-storage/indexed-db.context";
import { useIndexedDB } from "@common/contexts/web-storage/indexed-db.context";

const DEFAULT_TABLES_TO_CLEAR: TTableName[] = [
  "ProfilesTable",
  "QuestionsTable",
  "SkillsTable",
];

/**
 * Helper method to clear tables of a database.
 *
 * @param database - The database to clear.
 * @param tablesToClear - The tables to clear.
 */
export const clearTables = async (
  database: IIndexDBContext,
  tablesToClear: TTableName[] = DEFAULT_TABLES_TO_CLEAR
) => {
  const tablesToClearPromises = tablesToClear.map((table) =>
    database[table].clear()
  );

  await Promise.all(tablesToClearPromises);
};

export const useDexieDBCleaner = (tablesToClear: TTableName[] = []) => {
  const database = useIndexedDB();

  useEffect(() => {
    clearTables(database, tablesToClear).catch(() => {
      // eslint-disable-next-line no-console
      console.error("Something went wrong while cleaning tables.");
    });
  }, [database, tablesToClear]);
};
