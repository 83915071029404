import type { TToastStackType } from "../../toast-stack.definitions";

const BACKGROUND_COLORS: Readonly<Record<TToastStackType, string>> = {
  success: "green",
  information: "blue",
  warning: "orange",
  danger: "red",
  neutral: "gray",
};

export const getBackgroundColor = (messageType: TToastStackType) => {
  return BACKGROUND_COLORS[messageType] ?? "gray";
};

const FONT_COLORS: Readonly<Partial<Record<TToastStackType, string>>> = {};

export const getFontColor = (messageType: TToastStackType) => {
  return FONT_COLORS[messageType] ?? "white";
};
