import { createContext, useContext } from "react";

import type { FunctionComponent, ReactNode } from "react";

import type {
  TToastMessage,
  TToastMessageQueue,
  TToastStackType,
} from "../../toast-stack.definitions";

import * as ToastStackAction from "./state/toast-stack.actions";
import { useToastStackReducer } from "./state/toast-stack.reducer";

type TToastStackProps = {
  children: ReactNode;
};

type TToastStackContext = {
  messages: TToastMessageQueue[];

  addMessage: (message: TToastMessage) => void;
  removeMessageById: (id: string) => void;
  removeAllMessages: () => void;
  removeMessageByType: (type: TToastStackType) => void;
};

const ToastStackContext = createContext<TToastStackContext | undefined>(
  undefined
);

const ToastProvider: FunctionComponent<TToastStackProps> = ({ children }) => {
  const [state, dispatch] = useToastStackReducer();

  const { messages } = state;

  const addMessage = (message: TToastMessage) => {
    const messageToQueue: TToastMessageQueue = {
      ...message,
      id: crypto.randomUUID(),
    };

    dispatch(ToastStackAction.addMessage(messageToQueue));
  };

  const removeMessageById = (id: string) => {
    dispatch(ToastStackAction.removeMessageById(id));
  };

  const removeAllMessages = () => {
    dispatch(ToastStackAction.removeAllMessages());
  };

  const removeMessageByType = (type: TToastStackType) => {
    dispatch(ToastStackAction.removeMessageByType(type));
  };

  const contextValue: TToastStackContext = {
    // Properties
    messages,

    // Methods
    addMessage,
    removeMessageById,
    removeAllMessages,
    removeMessageByType,
  };

  return (
    <ToastStackContext.Provider value={contextValue}>
      {children}
    </ToastStackContext.Provider>
  );
};

export const useToastStack = () => {
  const context = useContext(ToastStackContext);

  if (context === undefined) {
    throw new Error("useToastStack must be used within a ToastStackProvider");
  }

  return context;
};

export default ToastProvider;
